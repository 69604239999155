export const workCenterItemIntegers = [];
export const workCenterItemFloats = [];
export const workCenterItemIsoStrings = [];
export const workCenterItemWhiteList = [
  // 'workCenterCategoryId',
  'position',
  'itemDescription',
  'itemNotes',
  'itemCostType',
  'itemUnitOfMeasure',
  'itemValidationNotes',
  'itemMinimumChargeNotes',
  'itemCustom',
  'minimumChargeUnitOfMeasure',
  'minimumChargeQuantity',
  // 'workCenterItemPricings',
];
export const workCenterItemOptimisticList = [...workCenterItemWhiteList];
