import { useState, useEffect } from 'react';
import {
  Navbar,
  Nav,
  NavDropdown,
  OverlayTrigger,
  Tooltip,
  Button,
  Badge,
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import {
  AlertCircle as AlertCircleIcon,
  Wifi as WifiIcon,
  WifiOff as WifiOffIcon,
  // Sliders as SlidersIcon,
  // Users as UsersIcon,
  // Lock as LockIcon,
} from 'react-feather';

import LinkContainer from '../components/link_container';
import Confirm from '../components/confirm';
import { clearLocalStorage } from '../lib/local_storage';
// import Glyphicon from '../components/glyphicon';
import { settingsSet } from '../store/settings_slice';

export default function Header() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const settingsOnline = useSelector((state) => state.settings.online);
  const currentUser = useSelector((state) => state.auth.user);
  const versionCurrent = useSelector((state) => state.settings.versionCurrent);
  const settingsTenantHasQccInspections = useSelector(
    (state) => state.settings.tenantHasQccInspections
  );
  const settingsTenantHasReinspections = useSelector(
    (state) => state.settings.tenantHasReinspections
  );
  const trackedMutationCount = useSelector(
    (state) => state.settings.trackedMutationCount
  );
  const currentBootswatchStyle = useSelector((state) => state.settings.bootswatchStyle);
  const tenantShortName = useSelector((state) => state.settings.tenantShortName);
  // const [isInspector, setIsInspector] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  // const [isDeveloper, setIsDeveloper] = useState(false);

  useEffect(() => {
    // let newIsDeveloper = false;
    // let newIsInspector = false;
    let newIsAdmin = false;
    if (currentUser && currentUser.id) {
      // newIsInspector = currentUser.inspector;
      newIsAdmin = currentUser.admin;
      // newIsDeveloper = currentUser.limited;
    }
    // setIsDeveloper(newIsDeveloper);
    // setIsInspector(newIsInspector);
    setIsAdmin(newIsAdmin);
  }, [currentUser]);

  useEffect(() => {
    const link = document.querySelector('link[title=bootswatch]');
    if (link) {
      const href = link.getAttribute('href');
      if (href) {
        const newHref = href.replace(
          /dist\/.+\/bootstrap.min.css/,
          `dist/${currentBootswatchStyle}/bootstrap.min.css`
        );
        link.setAttribute('href', newHref);
      }
    }
  }, [currentBootswatchStyle]);

  const onRefreshClicked = async () => {
    await clearLocalStorage();
    window.location.reload(true);
  };

  const onResetClicked = () => {
    navigate('/auth/logout?reset');
  };

  const onReloadClicked = () => {
    window.location.reload(true);
  };

  const handleStyleChange = (bootswatchStyle) =>
    dispatch(
      settingsSet({
        bootswatchStyle,
      })
    );

  return (
    <Navbar defaultExpanded expand="xl" variant="light" bg="light" className="px-4">
      <Navbar.Brand href="/" className="text-dark">
        {tenantShortName}
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <div className="w-100 d-flex justify-content-between flex-nowrap">
        <Navbar.Collapse id="left-navbar-nav" className="justify-content-start">
          <Nav>
            <Nav.Item className="me-2">
              <LinkContainer to={{ pathname: '/claims' }}>
                <Nav.Link>Claims</Nav.Link>
              </LinkContainer>
            </Nav.Item>
            {settingsTenantHasReinspections && (
              <Nav.Item className="me-2">
                <LinkContainer to={{ pathname: '/reinspections' }}>
                  <Nav.Link>Reinspections</Nav.Link>
                </LinkContainer>
              </Nav.Item>
            )}
            <Nav.Item className="me-2">
              <LinkContainer to={{ pathname: '/inspected' }}>
                <Nav.Link>Inspected</Nav.Link>
              </LinkContainer>
            </Nav.Item>
            {settingsTenantHasQccInspections && (
              <Nav.Item className="me-2">
                <LinkContainer to={{ pathname: '/qcc_inspections' }}>
                  <Nav.Link>QCC Inspections</Nav.Link>
                </LinkContainer>
              </Nav.Item>
            )}
            {isAdmin && (
              <Nav.Item className="me-2">
                <LinkContainer to={{ pathname: '/users' }}>
                  <Nav.Link eventKey={3}>Users</Nav.Link>
                </LinkContainer>
              </Nav.Item>
            )}
            {isAdmin && (
              <NavDropdown
                title="Work Breakdown"
                id="basic-nav-dropdown-other"
                className="me-2"
              >
                <LinkContainer to={{ pathname: '/work_center_categories' }}>
                  <NavDropdown.Item eventKey={5.1}>Work Centers</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to={{ pathname: '/oac_items' }}>
                  <NavDropdown.Item eventKey={5.2}>OAC Items</NavDropdown.Item>
                </LinkContainer>
              </NavDropdown>
            )}
            {versionCurrent || (
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip id="tooltip-header-version-link">
                    Your application version is out of date. Click to refresh your browser
                  </Tooltip>
                }
              >
                <Button variant="danger" onClick={onReloadClicked}>
                  <AlertCircleIcon size="16" />
                </Button>
              </OverlayTrigger>
            )}
          </Nav>
        </Navbar.Collapse>
        <Navbar.Collapse id="right-navbar-nav" className="justify-content-end">
          <Nav>
            <Nav.Item className="me-3">
              {settingsOnline ? (
                <OverlayTrigger
                  key="online"
                  placement="bottom"
                  overlay={<Tooltip id="tooltip-online">You are online</Tooltip>}
                >
                  <WifiIcon />
                </OverlayTrigger>
              ) : (
                <OverlayTrigger
                  key="offline"
                  placement="bottom"
                  overlay={
                    <Tooltip id="tooltip-offline">
                      You are offline. Not all data may be available
                    </Tooltip>
                  }
                >
                  <WifiOffIcon color="red" />
                </OverlayTrigger>
              )}
            </Nav.Item>
            <Nav.Item className="me-2">
              <OverlayTrigger
                key="online"
                placement="bottom"
                overlay={<Tooltip id="tooltip-online">Sync Queue</Tooltip>}
              >
                <Badge
                  pill
                  variant={trackedMutationCount > 0 ? 'danger' : 'secondary'}
                  size="lg"
                >
                  {trackedMutationCount}
                </Badge>
              </OverlayTrigger>
            </Nav.Item>
          </Nav>
          <Nav activeKey={currentBootswatchStyle} onSelect={handleStyleChange}>
            <NavDropdown title="Themes" id="basic-nav-dropdown-themes">
              <NavDropdown.Item eventKey="simplex">Simplex</NavDropdown.Item>
              <NavDropdown.Item eventKey="sandstone">Sandstone</NavDropdown.Item>
              <NavDropdown.Item eventKey="darkly">Darkly</NavDropdown.Item>
              <NavDropdown.Item eventKey="zephyr">Zephyr</NavDropdown.Item>
              <NavDropdown.Item eventKey="cosmo">Cosmo</NavDropdown.Item>
              <NavDropdown.Item eventKey="flatly">Flatly</NavDropdown.Item>
              <NavDropdown.Item eventKey="sketchy">Sketchy</NavDropdown.Item>
              <NavDropdown.Item eventKey="spacelab">Spacelab</NavDropdown.Item>
              <NavDropdown.Item eventKey="journal">Journal</NavDropdown.Item>
              <NavDropdown.Item eventKey="morph">Morph</NavDropdown.Item>
              <NavDropdown.Item eventKey="yeti">Yeti</NavDropdown.Item>
            </NavDropdown>
          </Nav>
          <Nav>
            <NavDropdown align="end" title="Tools" id="basic-nav-dropdown-other">
              <NavDropdown.Item eventKey={8.1} href="#" onClick={onRefreshClicked}>
                Reload
              </NavDropdown.Item>
              <Confirm
                onConfirm={onResetClicked}
                title="Reset all"
                body="This will reset the service and log you out. Any changes made while offline will be lost."
              >
                <NavDropdown.Item eventKey={8.2} href="#" onClick={onResetClicked}>
                  Reset
                </NavDropdown.Item>
              </Confirm>
              <LinkContainer to={{ pathname: '/auth/logout' }}>
                <NavDropdown.Item eventKey={8.3}>Sign Out</NavDropdown.Item>
              </LinkContainer>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </div>
    </Navbar>
  );
}
