import {
  Modal,
  Form,
  Button,
  Row,
  Col,
  ButtonToolbar,
  ButtonGroup,
} from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';

import { Form as FinalForm } from 'react-final-form';
import setFieldTouched from 'final-form-set-field-touched';
import arrify from 'arrify';

import curry from 'lodash.curry';
import get from 'lodash.get';
import clone from 'lodash.clone';
import pick from 'lodash.pick';
import round from 'lodash.round';
import reverse from 'lodash.reverse';
import sortBy from 'lodash.sortby';

import SubmitButtonSpinner from '../components/submit_button_spinner';
import Field from '../components/form/rff_field';
import OnChangeField from '../components/form/rff_on_change_field';
import InputField from '../components/form/input_field';
import CheckboxInputField from '../components/form/checkbox_input_field';
import InvalidBlock from '../components/form/invalid_block';
import LabelBlock from '../components/form/label_block';
import { pickValues } from '../lib/utils';
import { workCenterItemFormValidator } from '../validators';

import { workCenterItemWhiteList } from '../white_lists';

const WorkCenterItemForm = ({
  open,
  workCenterCategory,
  workCenterCategoryList,
  enums,
  workCenterItem,
  handleSetModalToggle,
  handleWorkCenterItemSubmit,
}) => {
  let clickedSubmitButton = 'new';

  const sortedWorkCenterItemPricings = () =>
    reverse(
      sortBy(clone(get(workCenterItem, ['workCenterItemPricings'], [])), ['createdAt'])
    );

  // eslint-disable-next-line arrow-body-style
  const getInitialValues = () => {
    return {
      // defaults
      itemCostType: 'PC Sum',
      itemUnitOfMeasure: 'Each', // probably not ever used
      itemCustom: false, // probably not ever used
      // actuals
      ...pickValues(workCenterItem, workCenterItemWhiteList),
      // synthetics
      workCenterName: workCenterCategory.workCenterName, // convert to work_center_category_id
      itemPricingFixed: get(
        sortedWorkCenterItemPricings(),
        [0, 'itemPricingFixed'],
        false
      ), // convert to new itemPricing entry on change
      itemPricingUnitPrice: get(
        sortedWorkCenterItemPricings(),
        [0, 'itemPricingUnitPrice'],
        ''
      ), // convert to new itemPricing entry on change
    };
  };

  const onHide = () => handleSetModalToggle && handleSetModalToggle();
  const onCancel = () => handleSetModalToggle && handleSetModalToggle();

  const onFormSubmit = (data, form) => {
    handleWorkCenterItemSubmit(data, clickedSubmitButton);
    if (clickedSubmitButton === 'new') {
      form.restart(
        pick(data, [
          'itemUnitOfMeasure',
          'itemCostType',
          'itemCustom',
          'itemPricingFixed',
          'workCenterName',
        ])
      );
    }
  };

  const onItemCostTypeChange = (form, values) => {
    const { itemCostType } = values;
    if (itemCostType === 'Incl.') {
      form.batch(() => {
        form.change('itemPricingUnitPrice', '0');
      });
    }
    if (itemCostType !== 'MC') {
      form.batch(() => {
        form.change('itemUnitOfMeasure', '');
        form.change('itemMinimumChargeNotes', '');
        form.change('minimumChargeUnitOfMeasure', '');
        form.change('minimumChargeQuantity', '');
      });
    }
    if (itemCostType === 'MC') {
      form.batch(() => {
        form.change('itemUnitOfMeasure', 'Min Charge');
      });
    }
  };

  const onTypeaheadBlur = curry((form, name, e) => {
    e.preventDefault();
    form.mutators.setFieldTouched(name, true);
  });

  const onTypeaheadChange = curry((form, name, value) => {
    let formValue = value[0] || null;
    if (formValue && formValue.id) {
      formValue = formValue.id;
    }
    form.change(name, formValue);
  });

  const onFloatBlur = curry((form, name, onBlur, e) => {
    const value = Number.isNaN(parseFloat(e.target.value))
      ? ''
      : round(parseFloat(e.target.value), 2);
    form.change(name, value);
    onBlur(e);
  });

  const getOptionsForItemUnitOfMeasure = (itemCostType) => {
    if (itemCostType === 'MC') {
      return [{ id: 'Min Charge', name: 'Min Charge' }];
    }
    return enums.ItemUnitOfMeasures.filter(
      (unit) => unit !== 'Min Charge' && unit !== 'MC'
    ).map((unit) => ({
      id: unit,
      name: unit,
    }));
  };

  const renderContent = () => (
    <>
      <Modal.Header>
        <Modal.Title>
          {workCenterItem.id ? 'Update Work Center Item' : 'Create Work Center Item'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FinalForm
          initialValues={getInitialValues()}
          onSubmit={onFormSubmit}
          validate={workCenterItemFormValidator}
          mutators={{ setFieldTouched }}
        >
          {({ handleSubmit, pristine, form, submitting, values }) => (
            <form onSubmit={handleSubmit} noValidate>
              <Row>
                <Field name="workCenterName">{() => null}</Field>
                <OnChangeField name="itemCostType">
                  {() => onItemCostTypeChange(form, values)}
                </OnChangeField>
                <Row className="mb-3">
                  <Field
                    name="itemCostType"
                    component={InputField}
                    asElement="select"
                    selectOptions={enums.ItemCostTypes.map((costType) => ({
                      id: costType,
                      name: costType,
                    }))}
                    defaultSelectOptionName="select cost type..."
                    size="md"
                    labelWidth={0}
                    inputWidth={6}
                  >
                    Cost Type
                  </Field>
                  <InputField
                    size="md"
                    labelWidth={0}
                    inputWidth={6}
                    input={{
                      name: 'workCenterName',
                    }}
                    innerContent={
                      <>
                        <LabelBlock size="md">Work Center</LabelBlock>
                        <Typeahead
                          id="work-center-name-typeahead-single"
                          labelKey="workCenterName"
                          onBlur={onTypeaheadBlur(form, 'workCenterName')}
                          onChange={onTypeaheadChange(form, 'workCenterName')}
                          options={workCenterCategoryList
                            .map(({ workCenterName }) => workCenterName)
                            .sort()}
                          placeholder="select workcenter..."
                          selected={arrify(values.workCenterName)}
                        />
                        <InvalidBlock
                          meta={form.getFieldState('workCenterName') || {}}
                          force
                        />
                      </>
                    }
                  />
                </Row>
                <Field
                  name="itemDescription"
                  component={InputField}
                  asElement="textarea"
                  rows={2}
                  size="md"
                >
                  Description
                </Field>
                <hr />
                {values.itemCostType === 'MC' && (
                  <>
                    <Row className="mb-3">
                      <Field
                        name="minimumChargeQuantity"
                        component={InputField}
                        customOnBlur={onFloatBlur(form)}
                        labelWidth={0}
                        inputWidth={3}
                      >
                        Minimum Charge Quantity
                      </Field>
                      <Field
                        name="minimumChargeUnitOfMeasure"
                        component={InputField}
                        asElement="select"
                        selectOptions={enums.MinimumChargeUnitOfMeasures.map((uom) => ({
                          id: uom,
                          name: uom,
                        }))}
                        defaultSelectOptionName="select unit of measure..."
                        labelWidth={0}
                        inputWidth={3}
                      >
                        Minimum Charge Unit of Measure
                      </Field>
                      <Field
                        name="itemMinimumChargeNotes"
                        component={InputField}
                        asElement="textarea"
                        rows={1}
                        labelWidth={0}
                        inputWidth={6}
                      >
                        Minimum Charge Notes
                      </Field>
                    </Row>
                    <hr />
                  </>
                )}
                <Field
                  name="itemUnitOfMeasure"
                  component={InputField}
                  asElement="select"
                  selectOptions={getOptionsForItemUnitOfMeasure(values.itemCostType)}
                  defaultSelectOptionName="select unit of measure..."
                  disabled={values.itemCostType === 'MC'}
                  labelWidth={0}
                  inputWidth={4}
                >
                  Unit of Measure
                </Field>
                <Field
                  name="itemPricingUnitPrice"
                  component={InputField}
                  customOnBlur={onFloatBlur(form)}
                  labelWidth={0}
                  inputWidth={4}
                >
                  Item Price
                </Field>
                <Field
                  type="checkbox"
                  name="itemPricingFixed"
                  component={CheckboxInputField}
                  labelWidth={0}
                  inputWidth={4}
                  inlineLabel={false}
                >
                  Fixed Price
                </Field>
                <hr />
                <Form.Group as={Row}>
                  <Col sm={12}>
                    <ButtonToolbar style={{ justifyContent: 'flex-end' }}>
                      <ButtonGroup className="me-2">
                        <Button variant="danger" onClick={onCancel} disabled={submitting}>
                          Cancel
                        </Button>
                      </ButtonGroup>
                      <ButtonGroup className="me-2">
                        <Button
                          type="submit"
                          variant="primary"
                          disabled={pristine || submitting}
                          onClick={(event) => {
                            clickedSubmitButton = 'new';
                            handleSubmit(event);
                          }}
                        >
                          {submitting && <SubmitButtonSpinner />}
                          {workCenterItem.id ? 'Update and New' : 'Create and New'}
                        </Button>
                        <Button
                          type="submit"
                          variant="primary"
                          disabled={pristine || submitting}
                          onClick={(event) => {
                            clickedSubmitButton = 'close';
                            handleSubmit(event);
                          }}
                        >
                          {submitting && <SubmitButtonSpinner />}
                          {workCenterItem.id ? 'Update and Close' : 'Create and Close'}
                        </Button>
                      </ButtonGroup>
                    </ButtonToolbar>
                  </Col>
                </Form.Group>
              </Row>
            </form>
          )}
        </FinalForm>
      </Modal.Body>
    </>
  );

  return (
    <div>
      <Modal show={open} onHide={onHide} size="lg" centered>
        {renderContent()}
      </Modal>
    </div>
  );
};

export default WorkCenterItemForm;
