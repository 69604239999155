import { useState, useRef, useMemo } from 'react';
import { Button, Col, Row, ButtonToolbar, ButtonGroup, Modal } from 'react-bootstrap';
import { useQuery, useMutation, NetworkStatus } from '@apollo/client';
import { useNavigate, useParams } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { Form as FinalForm } from 'react-final-form';

import { capitalCase } from 'change-case';

import cloneDeep from 'lodash.clonedeep';
import curry from 'lodash.curry';

import { settingsSet } from '../store/settings_slice';
import LinkContainer from '../components/link_container';
import { renderOverlay, renderError, renderOffline } from '../components/render_helpers';
import SubmitButtonSpinner from '../components/submit_button_spinner';
import ClaimReinspectionFormFields from '../components/claim_reinspection_form/claim_reinspection_form_fields';

import { toastSuccess, toastWarning, toastError } from '../lib/toast_helpers';
import { coerceInput, pickValues, handleSubmitError } from '../lib/utils';
import { reinspectionWhiteList } from '../white_lists';
import * as updateFunctions from '../update_functions';
import { reinspectionFormValidator } from '../validators';
import { reinspectionDefaultValues } from '../defaults';

import { firstReinspectionUpdate as firstReinspectionUpdateMutation } from '../graphql/first_reinspection_queries';
import { claimReinspectionFormPageQuery } from '../graphql/claim_reinspection_queries';

const ClaimFirstReinspectionForm = () => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const focusInputEl = useRef(null);
  const [focusModalShow, setFocusModalShow] = useState(false);
  const [focusModalField, setFocusModalField] = useState({
    name: '',
    value: '',
    form: null,
  });

  const settingsMutating = useSelector((state) => state.settings.mutating);
  const settingsOnline = useSelector((state) => state.settings.online);
  const settingsTenant = useSelector((state) => state.settings.tenant);

  const [firstReinspectionUpdate] = useMutation(firstReinspectionUpdateMutation);

  const {
    data: pageData,
    loading: pageLoading,
    error: pageError,
    networkStatus: pageNetworkStatus,
  } = useQuery(claimReinspectionFormPageQuery, {
    variables: { claimId: params.id },
    notifyOnNetworkStatusChange: true,
  });

  const pageLoadedOrRefetching = useMemo(
    () => !pageLoading || (pageLoading && pageNetworkStatus === NetworkStatus.refetch),
    [pageLoading, pageNetworkStatus]
  );

  const initialValues = useMemo(() => {
    if (pageData?.claim?.firstReinspection) {
      return pickValues(pageData.claim.firstReinspection, reinspectionWhiteList);
    }
    return pickValues(reinspectionDefaultValues, reinspectionWhiteList);
  }, [pageData]);

  const hideFocusModal = () => {
    setFocusModalField({ name: '', value: '', form: null });
    setFocusModalShow(false);
  };

  const saveFocusModal = () => {
    focusModalField.form.change(focusModalField.name, focusInputEl.current.value);
    hideFocusModal();
  };

  const onFocusModal = curry((form, name, onFocus, e) => {
    e.preventDefault();
    window.document.activeElement.blur();
    const fieldState = form.getFieldState(name);
    setFocusModalField({ name, value: fieldState.value || '', form });
    setFocusModalShow(true);
  });

  const enterFocusModal = () => {
    const { value } = focusInputEl.current;
    focusInputEl.current.value = null;
    focusInputEl.current.value = value;
    focusInputEl.current.focus();
  };

  const onCancel = () => {
    navigate('/reinspections');
  };

  const onFormSubmit = async (data) => {
    const submitData = cloneDeep(data);
    submitData.claimId = params.id;
    const mutationData = {
      context: {
        serializationKey: settingsTenant,
        tracked: true,
        recordType: 'FirstReinspectionType',
        recordId: pageData.claim.firstReinspection.id,
        mutationType: 'UPDATE',
      },
      variables: {
        id: pageData.claim.firstReinspection.id,
        input: coerceInput(submitData),
      },
      update: updateFunctions.firstReinspectionUpdate,
    };
    mutationData.optimisticResponse = updateFunctions.optimistic(
      'firstReinspectionUpdate',
      mutationData
    );
    const mutation = firstReinspectionUpdate;
    const mutationMessageAction = 'update';
    dispatch(
      settingsSet({
        mutating: true,
      })
    );
    if (settingsOnline) {
      try {
        await mutation(mutationData);
        toastSuccess(`First Reinspection ${mutationMessageAction} succeeded`);
        dispatch(
          settingsSet({
            mutating: false,
          })
        );
        navigate(-1);
      } catch (err) {
        const { errorMessage, submitErrors } = handleSubmitError(err);
        dispatch(
          settingsSet({
            mutating: false,
          })
        );
        toastError(errorMessage);
        return submitErrors;
      }
    } else {
      mutation(mutationData);
      toastWarning(
        `First Reinspection ${mutationMessageAction} ok locally. Go online to make permanent`
      );
      dispatch(
        settingsSet({
          mutating: false,
        })
      );
      navigate(-1);
    }
    return undefined;
  };

  const renderContent = () => {
    const {
      claim: { id },
    } = pageData;
    return (
      <>
        <Modal
          show={focusModalShow}
          onHide={hideFocusModal}
          size="xl"
          onEntered={enterFocusModal}
          backdrop="static"
        >
          <Modal.Header>
            <Modal.Title>{`Editing ${capitalCase(focusModalField.name)}`}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <textarea
                ref={focusInputEl}
                type="text"
                defaultValue={focusModalField.value}
                style={{
                  fontSize: 'large',
                  width: '100%',
                  height: `calc(${window.innerHeight}px - 208px)`,
                  overflowX: 'scroll',
                }}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button type="button" variant="danger" onClick={saveFocusModal}>
              Save
            </Button>
            <Button type="button" variant="primary" onClick={hideFocusModal}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
        <Row className="mt-4 mb-3">
          <Col>
            <h1 className="h3 mb-3">Edit First Reinspection</h1>
          </Col>
          <Col sm="auto">
            <ButtonToolbar>
              <ButtonGroup>
                <LinkContainer to="/reinspections">
                  <Button variant="primary">Claims</Button>
                </LinkContainer>
                <LinkContainer to={`/claims/${id}`}>
                  <Button variant="primary">Job Sheet</Button>
                </LinkContainer>
              </ButtonGroup>
            </ButtonToolbar>
          </Col>
        </Row>
        <Row>
          <Col>
            <FinalForm
              initialValues={initialValues}
              onSubmit={(data) => onFormSubmit(data)}
              validate={reinspectionFormValidator}
            >
              {({ handleSubmit, pristine, submitting, form }) => (
                <form noValidate>
                  <ClaimReinspectionFormFields form={form} onFocusModal={onFocusModal} />
                  <Row>
                    <Col>
                      <ButtonToolbar style={{ justifyContent: 'flex-end' }}>
                        <ButtonGroup className="me-2">
                          <Button
                            variant="danger"
                            onClick={onCancel}
                            disabled={submitting}
                          >
                            Cancel
                          </Button>
                          <Button
                            type="button"
                            variant="primary"
                            disabled={pristine || submitting}
                            onClick={handleSubmit}
                          >
                            {submitting && <SubmitButtonSpinner />}
                            {params.id ? 'Update' : 'Create'}
                          </Button>
                        </ButtonGroup>
                      </ButtonToolbar>
                    </Col>
                  </Row>
                </form>
              )}
            </FinalForm>
          </Col>
        </Row>
      </>
    );
  };

  return (
    <div>
      {renderOverlay(pageLoading, settingsMutating, settingsOnline)}
      {renderOffline(settingsOnline)}
      {renderError(pageError)}
      {!pageError && pageLoadedOrRefetching && renderContent()}
    </div>
  );
};

export default ClaimFirstReinspectionForm;
