import { useMemo, useEffect, useState, useCallback } from 'react';
import { Col, Row, Card, Button, ButtonGroup } from 'react-bootstrap';
import { useQuery, useMutation, NetworkStatus } from '@apollo/client';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import ModalImage from 'react-modal-image';

import get from 'lodash.get';

import LinkContainer from '../components/link_container';
import ReactTable from '../components/react_table/react_table';
import { renderOverlay, renderOffline, renderError } from '../components/render_helpers';
import { toastSuccess, toastError } from '../lib/toast_helpers';
import { handleSubmitError } from '../lib/utils';

import {
  claimClaimAttachmentListPageQuery,
  claimQccPhotosPoll as claimQccPhotosPollMutation,
  claimClaimAttachmentsSignedUrlUpdate as claimClaimAttachmentsSignedUrlUpdateMutation,
} from '../graphql/claim_claim_attachment_queries';

const ClaimClaimAttachmentList = () => {
  const params = useParams();
  const [attachments, setAttachments] = useState([]);
  const settingsMutating = useSelector((state) => state.settings.mutating);
  const settingsOnline = useSelector((state) => state.settings.online);
  const [claimClaimAttachmentsSignedUrlUpdate] = useMutation(
    claimClaimAttachmentsSignedUrlUpdateMutation
  );
  const [claimQccPhotosPoll] = useMutation(claimQccPhotosPollMutation);

  const {
    data: pageData,
    loading: pageLoading,
    error: pageError,
    refetch: pageRefetch,
    networkStatus: pageNetworkStatus,
  } = useQuery(claimClaimAttachmentListPageQuery, {
    variables: { claimId: params.id },
    notifyOnNetworkStatusChange: true,
  });

  const pageLoadedOrRefetching = useMemo(
    () => !pageLoading || (pageLoading && pageNetworkStatus === NetworkStatus.refetch),
    [pageLoading, pageNetworkStatus]
  );

  const claim = useMemo(() => get(pageData, ['claim']), [pageData]);

  useEffect(() => {
    const setNewAttachments = async () => {
      let newAttachments = [];
      const claimAttachments = get(claim, ['claimAttachments'], []);
      if (claimAttachments.length) {
        const filteredAttachments = claimAttachments.filter((ci) => ci.signedUrl);
        if (settingsOnline) {
          const mutationData = {
            variables: {
              claimId: claim.id,
              input: { claimAttachmentIds: filteredAttachments.map((i) => i.id) },
            },
          };
          const signedUrlUpdates =
            await claimClaimAttachmentsSignedUrlUpdate(mutationData);
          newAttachments = get(
            signedUrlUpdates,
            ['data', 'claimClaimAttachmentsSignedUrlUpdate'],
            []
          );
        }
      }
      setAttachments(newAttachments);
    };
    setNewAttachments();
  }, [claim, settingsOnline, claimClaimAttachmentsSignedUrlUpdate]);

  const claimQccPhotosPollClicked = useCallback(
    () =>
      // eslint-disable-next-line implicit-arrow-linebreak
      claimQccPhotosPoll({
        variables: { id: claim.id },
      })
        .then(() => {
          toastSuccess(
            `New attachments requested.  Click 'refresh' in a minute for updates (if any)`
          );
        })
        .catch((err) => {
          const { errorMessage } = handleSubmitError(err);
          toastError(errorMessage);
        }),
    [claim, claimQccPhotosPoll]
  );

  const renderSignedUrl = ({ row }) => {
    const signedUrl = get(row, ['original', 'signedUrl']);
    const fileFilename = get(row, ['original', 'fileFilename'], 'Original');
    if (signedUrl) {
      return (
        <ModalImage
          small="https://upload.wikimedia.org/wikipedia/commons/thumb/f/f2/WPCA_-_Picture_icon_gray_infobox.svg/25px-WPCA_-_Picture_icon_gray_infobox.svg.png"
          large={signedUrl}
          alt={fileFilename}
          showRotate
        />
      );
    }
    return undefined;
  };

  const parentColumns = [
    {
      header: 'Id',
      accessorKey: 'id',
    },
    {
      header: 'File Name',
      accessorKey: 'fileFilename',
      enableColumnFilter: false,
    },
    {
      header: 'Type',
      accessorKey: 'fileMimetype',
      enableColumnFilter: false,
    },
    {
      header: 'View Image',
      accessorKey: 'signedUrl',
      enableColumnFilter: false,
      cell: renderSignedUrl,
    },
  ];

  const renderContent = () => (
    <>
      <Row className="mt-4 mb-3">
        <Col sm={12}>
          <div className="float-none">
            <div className="float-start">
              <h1 className="h3 mb-3">Claim Attachments</h1>
            </div>
            <div className="float-end">
              <ButtonGroup className="me-2">
                <Button
                  variant="primary"
                  onClick={claimQccPhotosPollClicked}
                  disabled={!settingsOnline}
                >
                  Poll New Attachments
                </Button>
              </ButtonGroup>
              <ButtonGroup>
                <Button
                  variant="primary"
                  onClick={() => pageRefetch()}
                  disabled={!settingsOnline}
                >
                  Refresh
                </Button>
                <LinkContainer to={`/claims/${params.id}`}>
                  <Button variant="primary">Job Sheet</Button>
                </LinkContainer>
              </ButtonGroup>
            </div>
          </div>
        </Col>
      </Row>
      {attachments && (
        <Row>
          <Col>
            <Card>
              <Card.Body>
                <ReactTable
                  rootName="claimClaimAttachment"
                  parentColumns={parentColumns}
                  data={attachments}
                  doShow={false}
                  doEdit={false}
                  doDelete={false}
                  hiddenColumns={['id']}
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}
    </>
  );

  return (
    <div>
      {renderOverlay(pageLoading, settingsMutating, settingsOnline)}
      {renderOffline(settingsOnline)}
      {renderError(pageError)}
      {!pageError && pageLoadedOrRefetching && renderContent()}
    </div>
  );
};

export default ClaimClaimAttachmentList;
