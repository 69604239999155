import { Col, Row, Card } from 'react-bootstrap';
import accounting from 'accounting';
import ReactTable from '../react_table/react_table';

export default function ClaimItemList({ claimItems, tableStateKey }) {
  const renderPriceCell = ({ itemCostType, itemQuantity, itemPricingUnitPrice }) => {
    if (itemQuantity && itemPricingUnitPrice) {
      return `${itemCostType}: ${itemQuantity} @ ${accounting.formatMoney(
        itemPricingUnitPrice
      )}`;
    }
    return itemCostType;
  };

  const parentColumns = [
    {
      header: 'Id',
      accessorKey: 'id',
    },
    {
      header: 'Floor',
      accessorKey: 'itemFloor',
      enableColumnFilter: false,
    },
    {
      header: 'Room',
      accessorKey: 'itemRoom',
      enableColumnFilter: false,
    },
    {
      header: 'Work Center',
      accessorKey: 'itemTrade',
      enableColumnFilter: false,
    },
    {
      header: 'Description',
      accessorKey: 'itemDescription',
      enableColumnFilter: false,
    },
    {
      header: 'Pricing',
      accessorKey: 'pricing',
      enableColumnFilter: false,
      accessorFn: renderPriceCell,
      style: { verticalAlign: 'top' },
    },
  ];

  return (
    <Row>
      <Col>
        <Card className="claimShowDetail">
          <Card.Body>
            <Card.Title>Schedule of Work</Card.Title>
            <ReactTable
              rootName={tableStateKey}
              parentColumns={parentColumns}
              data={claimItems}
              doShow={false}
              doEdit={false}
              hideActions
              hiddenColumns={['id']}
              hideResetTable
            />
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}
