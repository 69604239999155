import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router';

import { reduxPersistor } from '../store';
import { apolloClient, subscriptionClient } from '../lib/apollo_client';
import Api from '../lib/api';
import { removeIdToken, clearLocalStorage } from '../lib/local_storage';
import { authReset } from '../store/auth_slice';
import { toastSuccess } from '../lib/toast_helpers';

export default function Logout() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const settingsOnline = useSelector((state) => state.settings.online);

  useEffect(() => {
    const logout = async () => {
      try {
        removeIdToken();
        subscriptionClient.terminate();
        apolloClient.stop();
        await apolloClient.clearStore(); // empties the apollo cache
        if (location.search.includes('?reset')) {
          await reduxPersistor.purge(); // clears the redux persist localstorage key
          await clearLocalStorage(); // clears named local storage items
        }
        if (settingsOnline) {
          await Api.get('/auth/logout');
        }
        dispatch(authReset());
        toastSuccess('Logout successful');
        navigate('/auth/login');
      } catch (err) {
        console.log(err);
        dispatch(authReset());
      }
    };
    logout();
  }, [dispatch, navigate, location, settingsOnline]);

  return <p style={{ color: 'red' }}>Logging out...</p>;
}
