import { useMemo, useCallback } from 'react';
import { Col, Row, Card, Button, ButtonGroup, ButtonToolbar } from 'react-bootstrap';
import { useQuery, useMutation, NetworkStatus } from '@apollo/client';
import { useSelector, useDispatch } from 'react-redux';
import { validate as uuidValidate } from 'uuid';

import LinkContainer from '../components/link_container';
import { renderOverlay, renderOffline, renderError } from '../components/render_helpers';
import ReactTable from '../components/react_table/react_table';
import { toastSuccess, toastWarning, toastError } from '../lib/toast_helpers';
import { settingsSet, settingsReset } from '../store/settings_slice';

import { userDelete as userDeleteMutation, userListQuery } from '../graphql/user_queries';

import * as updateFunctions from '../update_functions';

const UserList = () => {
  const tableStateKey = 'user';
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.auth.user);
  const settingsMutating = useSelector((state) => state.settings.mutating);
  const settingsOnline = useSelector((state) => state.settings.online);
  const settingsTenant = useSelector((state) => state.settings.tenant);
  const [userDelete] = useMutation(userDeleteMutation);

  const {
    data: pageData,
    loading: pageLoading,
    error: pageError,
    refetch: pageRefetch,
    networkStatus: pageNetworkStatus,
    client: pageClient,
  } = useQuery(userListQuery, {
    notifyOnNetworkStatusChange: true,
    onCompleted: ({ userList }) => {
      // write an individual query for every user in the list
      updateFunctions.userList(pageClient, userList);
    },
  });

  const pageLoadedOrRefetching = useMemo(
    () => !pageLoading || (pageLoading && pageNetworkStatus === NetworkStatus.refetch),
    [pageLoading, pageNetworkStatus]
  );

  const tableResetClicked = useCallback(() => {
    dispatch(settingsReset(['tableState', tableStateKey]));
  }, [tableStateKey, dispatch]);

  const userDeleteClicked = async (e) => {
    const dataId = e.currentTarget.getAttribute('data-id');
    const userId = uuidValidate(dataId)
      ? dataId
      : parseInt(e.currentTarget.getAttribute('data-id'), 10);
    const mutationData = {
      variables: { id: userId },
      context: {
        serializationKey: settingsTenant,
        tracked: true,
        recordType: 'UserType',
        recordId: userId,
        mutationType: 'DELETE',
      },
      update: updateFunctions.userDelete,
    };
    mutationData.optimisticResponse = updateFunctions.optimistic(
      'userDelete',
      mutationData
    );
    dispatch(
      settingsSet({
        mutating: true,
      })
    );
    if (settingsOnline) {
      try {
        await userDelete(mutationData);
        toastSuccess('User delete ok');
      } catch (err) {
        console.log(err.toString());
        toastError('User delete failed');
      } finally {
        dispatch(
          settingsSet({
            mutating: false,
          })
        );
      }
    } else {
      userDelete(mutationData);
      toastWarning(`User delete ok locally. Go online to make permanent`);
      dispatch(
        settingsSet({
          mutating: false,
        })
      );
    }
  };

  const renderBooleanCell = (bool) => (bool ? 'Yes' : 'No');

  const parentColumns = [
    {
      header: 'Id',
      accessorKey: 'id',
      enableHiding: true,
    },
    {
      header: 'Email',
      accessorKey: 'email',
      enableColumnFilter: true,
      filterFn: 'includesString',
    },
    {
      header: 'Name',
      accessorKey: 'fullName',
      enableColumnFilter: true,
      filterFn: 'includesString',
    },
    {
      header: 'Admin?',
      accessorKey: 'admin',
      enableColumnFilter: false,
      accessorFn: (row) => renderBooleanCell(row.admin),
    },
    {
      header: 'Inspector?',
      accessorKey: 'inspector',
      enableColumnFilter: false,
      accessorFn: (row) => renderBooleanCell(row.inspector),
    },
  ];

  const renderContent = () => (
    <>
      <Row className="mt-4 mb-3">
        <Col>
          <h1 className="h3 mb-3">Users</h1>
        </Col>
        <Col sm="auto">
          <ButtonToolbar>
            <ButtonGroup className="me-2">
              <LinkContainer to="/users/new">
                <Button variant="primary">New User</Button>
              </LinkContainer>
            </ButtonGroup>
            <ButtonGroup>
              <Button variant="primary" onClick={tableResetClicked}>
                Reset Table
              </Button>
              <Button
                variant="primary"
                onClick={() => pageRefetch()}
                disabled={!settingsOnline}
              >
                Refresh Data
              </Button>
            </ButtonGroup>
          </ButtonToolbar>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <Card.Body>
              <ReactTable
                rootName={tableStateKey}
                parentColumns={parentColumns}
                data={pageData.userList}
                doShow={false}
                handleDelete={userDeleteClicked}
                currentUser={currentUser}
                hiddenColumns={['id']}
                hideResetTable
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );

  return (
    <div>
      {renderOverlay(pageLoading, settingsMutating, settingsOnline)}
      {renderOffline(settingsOnline)}
      {renderError(pageError)}
      {!pageError && pageLoadedOrRefetching && renderContent()}
    </div>
  );
};

export default UserList;
