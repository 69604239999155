/* eslint-disable react/jsx-props-no-spreading */
import { useState, useEffect, useMemo } from 'react';
import {
  ButtonGroup,
  Button,
  Col,
  Row,
  Card,
  Tab,
  Nav,
  Tabs,
  Form,
  ButtonToolbar,
} from 'react-bootstrap';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useQuery, useMutation, NetworkStatus } from '@apollo/client';
import { useNavigate, useParams } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { Trash as TrashIcon, Edit as EditIcon } from 'react-feather';
import { v4 as uuidv4, validate as uuidValidate } from 'uuid';

import cloneDeep from 'lodash.clonedeep';
import differenceWith from 'lodash.differencewith';
import filter from 'lodash.filter';
import first from 'lodash.first';
import last from 'lodash.last';
import forEach from 'lodash.foreach';
import get from 'lodash.get';
import groupBy from 'lodash.groupby';
import has from 'lodash.has';
import isEqual from 'lodash.isequal';
import map from 'lodash.map';
import keys from 'lodash.keys';
import reduce from 'lodash.reduce';
import round from 'lodash.round';
import set from 'lodash.set';
import setWith from 'lodash.setwith';
import sortBy from 'lodash.sortby';
import sum from 'lodash.sum';
import transform from 'lodash.transform';
import uniqBy from 'lodash.uniqby';
import values from 'lodash.values';

import { renderOverlay, renderOffline, renderError } from '../components/render_helpers';
import Confirm from '../components/confirm';

import ClaimClaimItemListCloneRoomForm from './claim_claim_item_list_clone_room_form';
import ClaimItemFormOac from './claim_item_form_oac';
import ClaimItemFormWorkCenter from './claim_item_form_work_center';
import ClaimItemFormManual from './claim_item_form_manual';

import { settingsSet } from '../store/settings_slice';

import {
  claimClaimItemListPageQuery,
  claimClaimItemCreate as claimClaimItemCreateMutation,
  claimClaimItemsCreate as claimClaimItemsCreateMutation,
  claimClaimItemUpdate as claimClaimItemUpdateMutation,
  claimClaimItemDelete as claimClaimItemDeleteMutation,
} from '../graphql/claim_claim_item_queries';
import {
  claimItemListPositionUpdate as claimItemListPositionUpdateMutation,
  claimItemListQuantityUpdate as claimItemListQuantityUpdateMutation,
} from '../graphql/claim_item_queries';

import { toastSuccess, toastWarning, toastError } from '../lib/toast_helpers';
import { coerceInput, pickValues, handleSubmitError } from '../lib/utils';
import * as updateFunctions from '../update_functions';
import { claimItemWhiteList } from '../white_lists/claim_item_form_white_lists';

const ClaimClaimItemList = () => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();

  const [editModalToggle, setEditModalToggle] = useState(false);
  const [tabFloor, setTabFloor] = useState('Ground Floor');
  const [tabRoom, setTabRoom] = useState('');
  const [claimItemList, setClaimItemList] = useState(null);
  const [selectedClaimItem, setSelectedClaimItem] = useState({});
  const [oacCategoryList, setOacCategoryList] = useState([]);
  const [oacMinChargeAccumulator, setOacMinChargeAccumulator] = useState({});
  const [workCenterCategoryList, setWorkCenterCategoryList] = useState([]);
  const [workCenterMinChargeAccumulator, setWorkCenterMinChargeAccumulator] = useState(
    {}
  );
  const [priceSchema, setPriceSchema] = useState('manual');
  const [cloneModalToggle, setCloneModalToggle] = useState(false);
  const [cloneRoomData, setCloneRoomData] = useState({});

  const settingsMutating = useSelector((state) => state.settings.mutating);
  const settingsOnline = useSelector((state) => state.settings.online);
  const settingsTenant = useSelector((state) => state.settings.tenant);

  const [claimClaimItemCreate] = useMutation(claimClaimItemCreateMutation);
  const [claimClaimItemsCreate] = useMutation(claimClaimItemsCreateMutation);
  const [claimClaimItemUpdate] = useMutation(claimClaimItemUpdateMutation);
  const [claimClaimItemDelete] = useMutation(claimClaimItemDeleteMutation);
  const [claimItemListPositionUpdate] = useMutation(claimItemListPositionUpdateMutation);
  const [claimItemListQuantityUpdate] = useMutation(claimItemListQuantityUpdateMutation);

  const {
    data: pageData,
    loading: pageLoading,
    error: pageError,
    networkStatus: pageNetworkStatus,
    refetch: pageRefetch,
  } = useQuery(claimClaimItemListPageQuery, {
    variables: { claimId: params.id },
    notifyOnNetworkStatusChange: true,
  });

  const pageLoadedOrRefetching = useMemo(
    () => !pageLoading || (pageLoading && pageNetworkStatus === NetworkStatus.refetch),
    [pageLoading, pageNetworkStatus]
  );

  const lastClaimItemForFloorAndRoom = useMemo(() => {
    if (pageData.claim?.claimItems.length > 0) {
      if (tabFloor && tabRoom) {
        const lastClaimItem = last(
          pageData.claim.claimItems.filter(
            (ci) => ci.itemFloor === tabFloor && ci.itemRoom === tabRoom
          )
        );
        return lastClaimItem;
      }
    }
    return undefined;
  }, [pageData, tabFloor, tabRoom]);

  useEffect(() => {
    if (!pageLoading) {
      if (pageData.claim) {
        // oac
        const oacMinCharge = reduce(
          groupBy(
            filter(
              pageData.claim.claimItems,
              (ci) => ci.itemUnitOfMeasure === 'Min Charge'
            ),
            'oacItemId'
          ),
          (result, claimItems, oacItemId) => {
            forEach(claimItems, (ci) => {
              setWith(
                result,
                [oacItemId, ci.id],
                [
                  round(ci.internalMinimumChargeQuantity || 0, 2),
                  round(ci.itemQuantity || 0, 2),
                ],
                Object
              );
            });
            return result;
          },
          {}
        );
        const oacMinChargeUpdates = [];
        forEach(oacMinCharge, (claimItems) => {
          if (sum(map(values(claimItems), last)) === 0) {
            oacMinChargeUpdates.push(first(keys(claimItems)));
          }
        });
        const oacCategories = map(sortBy(pageData.oacCategoryList, 'position'), (ic) => ({
          ...ic,
          oacItems: sortBy(ic.oacItems, 'position'),
        }));

        // oac
        const workCenterMinCharge = reduce(
          groupBy(
            filter(
              pageData.claim.claimItems,
              (ci) => ci.itemUnitOfMeasure === 'Min Charge'
            ),
            'workCenterItemId'
          ),
          (result, claimItems, workCenterItemId) => {
            forEach(claimItems, (ci) => {
              setWith(
                result,
                [workCenterItemId, ci.id],
                [
                  round(ci.internalMinimumChargeQuantity || 0, 2),
                  round(ci.itemQuantity || 0, 2),
                ],
                Object
              );
            });
            return result;
          },
          {}
        );
        const workCenterMinChargeUpdates = [];
        forEach(workCenterMinCharge, (claimItems) => {
          if (sum(map(values(claimItems), last)) === 0) {
            workCenterMinChargeUpdates.push(first(keys(claimItems)));
          }
        });
        const workCenterCategories = map(
          sortBy(pageData.workCenterCategoryList, 'position'),
          (wc) => ({
            ...wc,
            workCenterItems: sortBy(wc.workCenterItems, 'position'),
          })
        );

        const claimItemFloorRoom = reduce(
          groupBy(pageData.claim.claimItems, 'itemFloor'),
          (result, floorItems, floor) => {
            // eslint-disable-next-line no-param-reassign
            result[floor] = transform(
              groupBy(floorItems, 'itemRoom'),
              (roomResult, roomItems, room) => {
                // eslint-disable-next-line no-param-reassign
                roomResult[room] = sortBy(roomItems, 'position');
                return roomResult;
              },
              {}
            );
            return result;
          },
          {}
        );
        setClaimItemList(claimItemFloorRoom);
        const hasTabRoom = has(claimItemFloorRoom, [tabFloor, tabRoom]);
        if (!hasTabRoom) {
          const initialRoom = first(keys(get(claimItemFloorRoom, tabFloor, {})));
          setTabRoom(initialRoom || '');
        }
        setOacMinChargeAccumulator(oacMinCharge);
        setOacCategoryList(oacCategories);
        setWorkCenterMinChargeAccumulator(workCenterMinCharge);
        setWorkCenterCategoryList(workCenterCategories);
        // TODO MC will not work in offline mode (nor will drag and drop for positions)
        // WHen come to do actual OAC entries, will need to sort out the minimum
        // charge stuff locally
        if (oacMinChargeUpdates.length > 0) {
          claimItemListQuantityUpdate({
            variables: {
              input: {
                claimItems: map(oacMinChargeUpdates, (ci) => ({
                  id: ci,
                  itemQuantity: 1,
                })),
              },
            },
            refetchQueries: () => [
              { query: claimClaimItemListPageQuery, variables: { claimId: params.id } },
            ],
          });
        }
      } else {
        navigate('/claims');
      }
    }
  }, [pageLoading, pageData]);

  const handleSetCloneModalToggle = () => {
    setCloneModalToggle(!cloneModalToggle);
  };

  const handleCloneRoom = (e) => {
    const floor = e.currentTarget.getAttribute('data-floor');
    const room = e.currentTarget.getAttribute('data-room');
    setCloneRoomData({ floor, room });
    setCloneModalToggle(true);
  };

  const handleCloneRoomSubmit = async (data) => {
    // TODO This does not work properly offline.
    // Probably doue to lack of update function and guids.
    // Button is disabled
    const { floor: sourceFloor, room: sourceRoom } = cloneRoomData;
    const { destFloor, destRoom } = data;
    setCloneModalToggle(false);
    setCloneRoomData({});
    const cloneableClaimItems = get(pageData, 'claim.claimItems', []).filter(
      (ci) => ci.itemFloor === sourceFloor && ci.itemRoom === sourceRoom
    );
    if (cloneableClaimItems.length > 0) {
      const cloneableClaimItemWhiteList = [
        'firstReinspectionId',
        'secondReinspectionId',
        ...claimItemWhiteList,
      ];
      const clonedClaimItems = cloneableClaimItems.map((ci) => ({
        ...pickValues(ci, cloneableClaimItemWhiteList),
        claimId: params.id,
        itemFloor: destFloor,
        itemRoom: destRoom,
      }));
      const mutationDataInput = clonedClaimItems.map((ci) => coerceInput(ci));
      const mutationData = {
        variables: { claimId: params.id, input: mutationDataInput },
        context: {
          serializationKey: settingsTenant,
        },
      };
      mutationData.update = updateFunctions.claimClaimItemsCreate;
      const mutation = claimClaimItemsCreate;
      dispatch(
        settingsSet({
          mutating: true,
        })
      );
      if (settingsOnline) {
        try {
          await mutation(mutationData);
          toastSuccess(`Claim Items copied ok`);
          dispatch(
            settingsSet({
              mutating: false,
            })
          );
        } catch (err) {
          const { errorMessage, submitErrors } = handleSubmitError(err);
          dispatch(
            settingsSet({
              mutating: false,
            })
          );
          toastError(errorMessage);
          return submitErrors;
        }
      } else {
        mutation(mutationData);
        toastWarning(`Claim Items copied ok locally. Go online to make permanent`);
        dispatch(
          settingsSet({
            mutating: false,
          })
        );
      }
    }
    return undefined;
  };

  const setTabFloorClick = (floor) => {
    setTabFloor(floor);
    const initialRoom = first(keys(get(claimItemList, floor, {})));
    setTabRoom(initialRoom || '');
  };

  const claimItemLeaveClicked = () => {
    navigate(`/claims/${params.id}`);
  };

  const handleSetEditModalToggle = (e, submitType) => {
    if (submitType) {
      if (submitType === 'close') {
        setEditModalToggle(!editModalToggle);
      }
    } else {
      setEditModalToggle(!editModalToggle);
    }
    if (editModalToggle) {
      setSelectedClaimItem({});
    }
  };

  const handleClaimItemSubmit = async (data, submitType) => {
    handleSetEditModalToggle(undefined, submitType);
    const firstReinspection = get(pageData, 'claim.firstReinspection');
    const secondReinspection = get(pageData, 'claim.secondReinspection');
    const uuid = uuidv4();
    const submitData = cloneDeep(data);
    submitData.claimId = params.id;
    if (
      !selectedClaimItem.id ||
      selectedClaimItem.itemFloor !== submitData.itemFloor ||
      selectedClaimItem.itemRoom !== submitData.itemRoom
    ) {
      submitData.position =
        get(
          claimItemList,
          [submitData.itemFloor || 'null', submitData.itemRoom || 'null'],
          []
        ).length + 1;
    }
    if (secondReinspection) {
      submitData.secondReinspectionId = secondReinspection.id;
    } else if (firstReinspection) {
      submitData.firstReinspectionId = firstReinspection.id;
    }
    let mutation;
    let mutationMessageAction;
    const input = coerceInput(submitData);
    const mutationData = {
      variables: { claimId: params.id, input },
      context: {
        serializationKey: settingsTenant,
        tracked: true,
        recordType: 'ClaimItemType',
        recordId: selectedClaimItem.id || uuid,
      },
    };
    if (selectedClaimItem.id) {
      mutation = claimClaimItemUpdate;
      mutationMessageAction = 'update';
      mutationData.variables.id = selectedClaimItem.id;
      mutationData.context.mutationType = 'UPDATE';
      mutationData.update = updateFunctions.claimClaimItemUpdate;
      mutationData.optimisticResponse = updateFunctions.optimistic(
        'claimClaimItemUpdate',
        mutationData,
        { claimId: params.id }
      );
    } else {
      mutation = claimClaimItemCreate;
      mutationData.context.mutationType = 'CREATE';
      mutationMessageAction = 'create';
      mutationData.update = updateFunctions.claimClaimItemCreate;
      mutationData.optimisticResponse = updateFunctions.optimistic(
        'claimClaimItemCreate',
        mutationData,
        { claimId: params.id }
      );
    }
    dispatch(
      settingsSet({
        mutating: true,
      })
    );
    if (settingsOnline) {
      try {
        await mutation(mutationData);
        toastSuccess(`Claim Item ${mutationMessageAction} succeeded`);
        dispatch(
          settingsSet({
            mutating: false,
          })
        );
      } catch (err) {
        const { errorMessage, submitErrors } = handleSubmitError(err);
        dispatch(
          settingsSet({
            mutating: false,
          })
        );
        toastError(errorMessage);
        return submitErrors;
      }
    } else {
      mutation(mutationData);
      toastWarning(
        `Claim Item ${mutationMessageAction} ok locally. Go online to make permanent`
      );
      dispatch(
        settingsSet({
          mutating: false,
        })
      );
    }
    return undefined;
  };

  const onClaimItemDragEnd = (
    sourceFloor,
    sourceRoom,
    destinationFloor,
    destinationRoom,
    sourceIndex,
    destinationIndex
  ) => {
    const sourceFloorRoomList = get(claimItemList, [sourceFloor, sourceRoom]);
    const destinationFloorRoomList = get(claimItemList, [
      destinationFloor,
      destinationRoom,
    ]);
    const cloneList = cloneDeep(claimItemList);
    let sourceClaimItemFloorRoomList = get(cloneList, [sourceFloor, sourceRoom]);
    let destinationClaimItemFloorRoomList = get(cloneList, [
      destinationFloor,
      destinationRoom,
    ]);

    if (sourceIndex || sourceIndex === 0) {
      sourceClaimItemFloorRoomList.splice(sourceIndex, 1);
    }
    if (destinationIndex || destinationIndex === 0) {
      destinationClaimItemFloorRoomList.splice(
        destinationIndex,
        0,
        sourceFloorRoomList[sourceIndex]
      );
    }

    sourceClaimItemFloorRoomList = sortBy(
      map(sourceClaimItemFloorRoomList, (item, index) => ({
        ...item,
        position: index + 1,
      })),
      'position'
    );
    destinationClaimItemFloorRoomList = sortBy(
      map(destinationClaimItemFloorRoomList, (item, index) => ({
        ...item,
        position: index + 1,
      })),
      'position'
    );
    if (cloneList[sourceFloor][sourceRoom].length === 0) {
      if (keys(cloneList[sourceFloor]).length === 1) {
        delete cloneList[sourceFloor];
      } else if (keys(cloneList[sourceFloor][sourceRoom]).length === 1) {
        delete cloneList[sourceFloor][sourceRoom];
      } else {
        delete cloneList[sourceFloor][sourceRoom];
      }
    } else {
      set(cloneList, [sourceFloor, sourceRoom], sourceClaimItemFloorRoomList);
    }
    set(
      cloneList,
      [destinationFloor, destinationRoom],
      destinationClaimItemFloorRoomList
    );
    setClaimItemList(cloneList);

    const oldSourceIds = sortBy(
      map(sourceFloorRoomList, ({ id, itemFloor, itemRoom, position }) => ({
        id,
        itemFloor: itemFloor === 'null' ? null : itemFloor,
        itemRoom: itemRoom === 'null' ? null : itemRoom,
        position,
      })),
      'id'
    );
    const newSourceIds = sortBy(
      map(sourceClaimItemFloorRoomList, ({ id, position }) => ({
        id,
        itemFloor: sourceFloor === 'null' ? null : sourceFloor,
        itemRoom: sourceRoom === 'null' ? null : sourceRoom,
        position,
      })),
      'id'
    );
    const sourceDifferences = differenceWith(newSourceIds, oldSourceIds, isEqual);
    const oldDestinationIds = sortBy(
      map(destinationFloorRoomList, ({ id, itemFloor, itemRoom, position }) => ({
        id,
        itemFloor: itemFloor === 'null' ? null : itemFloor,
        itemRoom: itemRoom === 'null' ? null : itemRoom,
        position,
      })),
      'id'
    );
    const newDestinationIds = sortBy(
      map(destinationClaimItemFloorRoomList, ({ id, position }) => ({
        id,
        itemFloor: destinationFloor === 'null' ? null : destinationFloor,
        itemRoom: destinationRoom === 'null' ? null : destinationRoom,
        position,
      })),
      'id'
    );
    const destinationDifferences = differenceWith(
      newDestinationIds,
      oldDestinationIds,
      isEqual
    );
    const differences = uniqBy([...sourceDifferences, ...destinationDifferences], 'id');
    return claimItemListPositionUpdate({
      variables: {
        input: {
          claimItems: differences,
        },
      },
      refetchQueries: () => [
        { query: claimClaimItemListPageQuery, variables: { claimId: params.id } },
      ],
    });
  };

  const onDragEnd = (result) => {
    if (!settingsOnline) {
      return;
    }
    const { source, destination } = result;
    if (!destination) {
      return;
    }
    const { index: sourceIndex, droppableId: sourceDroppableId } = source;
    const { index: destinationIndex, droppableId: destinationDroppableId } = destination;
    const [sourceFloor, sourceRoom] = sourceDroppableId.split('-');
    const [destinationFloor, destinationRoom] = destinationDroppableId.split('-');
    if (
      sourceDroppableId !== destinationDroppableId ||
      sourceIndex !== destinationIndex
    ) {
      onClaimItemDragEnd(
        sourceFloor,
        sourceRoom,
        destinationFloor,
        destinationRoom,
        sourceIndex,
        destinationIndex
      );
    }
  };

  const claimItemEditEnabled = (claim, claimItem) => {
    const { firstReinspection, secondReinspection } = claim;
    const { firstReinspectionId, secondReinspectionId } = claimItem;
    if (
      !(firstReinspection || secondReinspection) ||
      (secondReinspection && secondReinspectionId) ||
      (firstReinspection && firstReinspectionId)
    ) {
      return true;
    }
    return false;
  };

  const claimItemEditClicked = (e) => {
    const dataId = e.currentTarget.getAttribute('data-id');
    const claimItemId = uuidValidate(dataId)
      ? dataId
      : parseInt(e.currentTarget.getAttribute('data-id'), 10);
    const claimItem = pageData.claim.claimItems.find((ci) => ci.id === claimItemId);
    let newPriceSchema = 'manual';
    if (claimItem.oacCategoryId) {
      newPriceSchema = 'oac';
    }
    if (claimItem.workCenterCategoryId) {
      newPriceSchema = 'workCenter';
    }
    setPriceSchema(newPriceSchema);
    setSelectedClaimItem(claimItem);
    handleSetEditModalToggle();
  };

  const claimItemDeleteClicked = async (e) => {
    const dataId = e.currentTarget.getAttribute('data-id');
    const claimItemId = uuidValidate(dataId)
      ? dataId
      : parseInt(e.currentTarget.getAttribute('data-id'), 10);
    const mutationData = {
      variables: { claimId: params.id, id: claimItemId },
      context: {
        serializationKey: settingsTenant,
        tracked: true,
        recordType: 'ClaimItemType',
        recordId: claimItemId,
        mutationType: 'DELETE',
      },
      update: updateFunctions.claimClaimItemDelete,
    };
    mutationData.optimisticResponse = updateFunctions.optimistic(
      'claimClaimItemDelete',
      mutationData,
      { claimId: params.id }
    );
    const mutation = claimClaimItemDelete;
    dispatch(
      settingsSet({
        mutating: true,
      })
    );
    if (settingsOnline) {
      try {
        await mutation(mutationData);
        toastSuccess('Claim Item delete ok');
      } catch (err) {
        console.log(err.toString());
        toastError('Claim Item delete failed');
      } finally {
        dispatch(
          settingsSet({
            mutating: false,
          })
        );
      }
    } else {
      mutation(mutationData);
      toastWarning(`Claim Item delete ok locally. Go online to make permanent`);
      dispatch(
        settingsSet({
          mutating: false,
        })
      );
    }
  };

  const renderTitle = () => {
    if (get(pageData, 'claim.secondReinspection')) {
      return 'Job Items - Second Reinspection';
    }
    if (get(pageData, 'claim.firstReinspection')) {
      return 'Job Items - First Reinspection';
    }
    return 'Job Items - Initial Inspection';
  };

  const renderSubtitle = (claimItem) => {
    const {
      firstReinspectionId,
      secondReinspectionId,
      itemCostType,
      itemQuantity,
      itemPricingUnitPrice,
    } = claimItem;
    let inspectionPosition = 'Initial inspection / ';
    if (firstReinspectionId) {
      inspectionPosition = 'First reinspection / ';
    }
    if (secondReinspectionId) {
      inspectionPosition = 'Second reinspection / ';
    }
    if (itemCostType && itemQuantity && itemPricingUnitPrice) {
      return `${inspectionPosition}${itemCostType}: ${itemQuantity} @ $${itemPricingUnitPrice}`;
    }
    if (itemCostType) {
      return `${inspectionPosition}${itemCostType}`;
    }
    return `${inspectionPosition}`;
  };

  const renderClaimItem = (draggableProvided, claimItem, claim) => (
    <Card
      border="secondary"
      className="mb-2"
      ref={draggableProvided.innerRef}
      {...draggableProvided.draggableProps}
      {...draggableProvided.dragHandleProps}
    >
      <Card.Body>
        <Row
          style={{
            flexWrap: 'nowrap',
            justifyContent: 'space-between',
            alignItems: 'baseline',
          }}
        >
          <Col
            style={{
              flexGrow: 10,
            }}
          >
            <Card.Title style={{ marginBottom: 0 }}>
              {claimItem.itemDescription.split('\n').map((desc, descIdx) => (
                // eslint-disable-next-line react/no-array-index-key
                <p key={`desc-${descIdx}`}>
                  {descIdx === 0 ? `${claimItem.itemTrade}: ${desc}` : desc}
                </p>
              ))}
            </Card.Title>
            <Card.Subtitle className="text-muted" style={{ marginTop: '2px' }}>
              {renderSubtitle(claimItem)}
            </Card.Subtitle>
          </Col>
          {claimItemEditEnabled(claim, claimItem) && (
            <Col style={{ textAlign: 'right' }}>
              <Button
                className="mb-2"
                onClick={claimItemEditClicked}
                data-id={claimItem.id}
                size="sm"
              >
                <EditIcon size="16" />
              </Button>
              <Confirm
                dataId={claimItem.id}
                onConfirm={claimItemDeleteClicked}
                title="Delete Claim Item"
                body="Are you sure you want to delete this claim item?"
              >
                <Button variant="danger" size="sm">
                  <TrashIcon size="16" />
                </Button>
              </Confirm>
            </Col>
          )}
        </Row>
      </Card.Body>
    </Card>
  );

  const renderContent = () => {
    const {
      claim,
      enums: { enums },
    } = pageData;
    return (
      <>
        <ClaimClaimItemListCloneRoomForm
          open={cloneModalToggle}
          sourceFloor={cloneRoomData.floor}
          sourceRoom={cloneRoomData.room}
          enums={enums}
          handleSetModalToggle={handleSetCloneModalToggle}
          handleCloneRoomSubmit={handleCloneRoomSubmit}
        />
        {priceSchema === 'manual' && (
          <ClaimItemFormManual
            open={editModalToggle}
            selectedFloor={tabFloor}
            selectedRoom={tabRoom}
            lastClaimItem={lastClaimItemForFloorAndRoom}
            claim={claim}
            workCenterCategoryList={workCenterCategoryList}
            oacCategoryList={oacCategoryList}
            enums={enums}
            handleSetModalToggle={handleSetEditModalToggle}
            handleClaimItemSubmit={handleClaimItemSubmit}
            claimItem={selectedClaimItem}
            oacMinChargeAccumulator={oacMinChargeAccumulator}
          />
        )}
        {priceSchema === 'oac' && (
          <ClaimItemFormOac
            open={editModalToggle}
            selectedFloor={tabFloor}
            selectedRoom={tabRoom}
            lastClaimItem={lastClaimItemForFloorAndRoom}
            claim={claim}
            oacCategoryList={oacCategoryList}
            enums={enums}
            handleSetModalToggle={handleSetEditModalToggle}
            handleClaimItemSubmit={handleClaimItemSubmit}
            claimItem={selectedClaimItem}
            oacMinChargeAccumulator={oacMinChargeAccumulator}
          />
        )}
        {priceSchema === 'workCenter' && (
          <ClaimItemFormWorkCenter
            open={editModalToggle}
            selectedFloor={tabFloor}
            selectedRoom={tabRoom}
            lastClaimItem={lastClaimItemForFloorAndRoom}
            claim={claim}
            workCenterCategoryList={workCenterCategoryList}
            enums={enums}
            handleSetModalToggle={handleSetEditModalToggle}
            handleClaimItemSubmit={handleClaimItemSubmit}
            claimItem={selectedClaimItem}
            workCenterMinChargeAccumulator={workCenterMinChargeAccumulator}
          />
        )}
        <Row className="mt-4 mb-3">
          <Col>
            <h1 className="h3 mb-3">{renderTitle()}</h1>
          </Col>
          <Col sm="auto">
            <ButtonToolbar>
              <ButtonGroup className="me-2">
                <Confirm
                  comfirmId="claim-item-leave"
                  onConfirm={claimItemLeaveClicked}
                  title="Confirm Schedule of Work Electrical"
                  body="Ensure electrical conection and reconnection tasks are included if required"
                >
                  <Button variant="primary">Job Sheet</Button>
                </Confirm>
              </ButtonGroup>
              <ButtonGroup>
                <Button
                  variant="primary"
                  onClick={() => pageRefetch()}
                  disabled={!settingsOnline}
                >
                  Refresh Data
                </Button>
              </ButtonGroup>
            </ButtonToolbar>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <Card.Body>
                <Form>
                  <Row>
                    <Col sm={2}>
                      <Button
                        onClick={handleSetEditModalToggle}
                        disabled={editModalToggle}
                      >
                        Add Job Item
                      </Button>
                    </Col>
                    <Col sm={2}>
                      <Form.Group
                        controlId="exampleForm.ControlSelect1"
                        style={{ marginBottom: 0 }}
                      >
                        <Form.Control
                          as="select"
                          value={priceSchema}
                          onChange={(e) => setPriceSchema(e.currentTarget.value)}
                        >
                          <option value="manual">Manual</option>
                          <option disabled value="oac">
                            OAC
                          </option>
                          <option value="workCenter">Work Center</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <DragDropContext onDragEnd={onDragEnd}>
          <Tabs
            id="controlled-tab-floors"
            activeKey={tabFloor}
            onSelect={(f) => setTabFloorClick(f)}
            style={{ marginBottom: '1rem' }}
          >
            {enums.ItemFloors.map((floor) => {
              const floorRooms = Object.keys(get(claimItemList, [floor], [])).sort();
              return (
                <Tab key={floor} eventKey={floor} title={floor}>
                  <Tab.Container
                    id="controlled-tab-rooms-left"
                    activeKey={tabRoom}
                    onSelect={(f) => setTabRoom(f)}
                  >
                    <Row>
                      <Col sm={3}>
                        <Nav variant="pills" className="flex-column">
                          {map(floorRooms, (room) => (
                            <Nav.Item key={room}>
                              <Nav.Link eventKey={room}>{room}</Nav.Link>
                            </Nav.Item>
                          ))}
                        </Nav>
                      </Col>
                      <Col sm={9}>
                        <Tab.Content>
                          {map(floorRooms, (room) => {
                            const claimItems = claimItemList[floor][room];
                            return (
                              <Tab.Pane key={room} eventKey={room}>
                                <Card>
                                  <Card.Header>
                                    <h3 className="float-start">
                                      {room === 'null' ? 'No Room' : room}
                                    </h3>
                                    <Button
                                      className="float-end"
                                      variant="link"
                                      data-floor={floor}
                                      data-room={room}
                                      onClick={handleCloneRoom}
                                      disabled={!settingsOnline}
                                    >
                                      Copy room
                                    </Button>
                                  </Card.Header>
                                  <Card.Body>
                                    <Droppable
                                      key={`${floor}-${room}-droppable`}
                                      droppableId={`${floor}-${room}`}
                                    >
                                      {(droppableProvided) => (
                                        <div
                                          className="mb-3"
                                          ref={droppableProvided.innerRef}
                                          {...droppableProvided.droppableProps}
                                        >
                                          {map(claimItems, (claimItem, index) => (
                                            <Draggable
                                              key={`claim-item-${claimItem.id}`}
                                              draggableId={`claim-item-${claimItem.id}`}
                                              index={index}
                                              isDragDisabled={!settingsOnline}
                                            >
                                              {(draggableProvided) =>
                                                renderClaimItem(
                                                  draggableProvided,
                                                  claimItem,
                                                  claim
                                                )
                                              }
                                            </Draggable>
                                          ))}
                                          {droppableProvided.placeholder}
                                        </div>
                                      )}
                                    </Droppable>
                                  </Card.Body>
                                </Card>
                              </Tab.Pane>
                            );
                          })}
                        </Tab.Content>
                      </Col>
                    </Row>
                  </Tab.Container>
                </Tab>
              );
            })}
          </Tabs>
        </DragDropContext>
      </>
    );
  };

  return (
    <div>
      {renderOverlay(pageLoading, settingsMutating, settingsOnline)}
      {renderOffline(settingsOnline)}
      {renderError(pageError)}
      {!pageError && pageLoadedOrRefetching && renderContent()}
    </div>
  );
};

export default ClaimClaimItemList;
