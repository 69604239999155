import { useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';

import ErrorBoundaryApp from './layouts/error_boundary_app';
import Header from './layouts/header';

import AppRoutes from './app_routes';

// bootstrap
// require('./css/corporate.css');
// require('./css/light.css');

// toasts // css no longer requried version 11
// require('react-toastify/dist/ReactToastify.css');

//
require('react-perfect-scrollbar/dist/css/styles.css');
//
// datetime picker
require('react-datetime/css/react-datetime.css');
require('react-bootstrap-typeahead/css/Typeahead.bs5.css');
require('./css/local.css');
// image gallery
require('react-image-gallery/styles/css/image-gallery.css');

const App = () => {
  const authIsAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  return (
    <ErrorBoundaryApp>
      <ToastContainer />
      {authIsAuthenticated && <Header />}
      <AppRoutes />
    </ErrorBoundaryApp>
  );
};

export default App;
