export const reinspectionIntegers = [];
export const reinspectionFloats = [];
export const reinspectionIsoStrings = [];
export const reinspectionWhiteList = [
  'description',
  'qualityIssues',
  'additionalWork',
  'updatedSketch',
  'additionalPhotos',
  'calledTradesman',
  'calledLossAdjustor',
  'tradesmanOnSite',
  'insuredHappyOverall',
  'insuredHappyTradesmanClean',
  'insuredHappyTradesmanPolite',
  'insuredGeneralComments',
];
export const reinspectionOptimisticList = [...reinspectionWhiteList];
