import { useCallback } from 'react';
import { Card, Table } from 'react-bootstrap';
import accounting from 'accounting';
import { useDispatch } from 'react-redux';
import { useMutation } from '@apollo/client';

import orderBy from 'lodash.orderby';

import ReactTable from '../react_table/react_table';
import EditableCell from '../react_table/react_table_editable_cell';

import { toastSuccess, toastError } from '../../lib/toast_helpers';
import { settingsSet } from '../../store/settings_slice';
import { oacItemPricingUpdate as oacItemPricingUpdateMutation } from '../../graphql/oac_item_queries';
import { coerceInput, handleSubmitError } from '../../lib/utils';

const OacItemListOacItemShow = ({ oacItem, tableStateKey }) => {
  const dispatch = useDispatch();
  const [oacItemPricingUpdate] = useMutation(oacItemPricingUpdateMutation);

  const {
    id,
    itemDescription,
    itemNotes,
    itemUnitOfMeasure,
    itemCategory,
    itemValidationNotes,
    itemMinimumChargeNotes,
    itemCustom,
    minimumChargeUnitOfMeasure,
    minimumChargeQuantity,
    oacItemPricings,
  } = oacItem;

  const beforeSaveCell = useCallback(async (oldVal, newVal, itemId, accessorKey) => {
    const oldValue = Number.isNaN(parseFloat(oldVal)) ? 0 : parseFloat(oldVal);
    const newValue = Number.isNaN(parseFloat(newVal)) ? 0 : parseFloat(newVal);

    if (oldValue !== newValue) {
      const submitData = {
        [accessorKey]: newValue,
      };

      const mutationData = {
        variables: {
          id: itemId,
          input: coerceInput(submitData),
        },
      };

      try {
        dispatch(
          settingsSet({
            mutating: true,
          })
        );
        await oacItemPricingUpdate(mutationData);
        toastSuccess(`OAC Item Pricing update succeeded`);
        dispatch(
          settingsSet({
            mutating: false,
          })
        );
      } catch (err) {
        const { errorMessage, submitErrors } = handleSubmitError(err);
        dispatch(
          settingsSet({
            mutating: false,
          })
        );
        toastError(errorMessage);
        return submitErrors;
      }
    }
    return undefined;
  }, []);

  const currencyFormatter = (value) => accounting.formatMoney(value);

  const renderBooleanCell = (row) => (row.itemPricingFixed ? 'Yes' : 'No');

  const renderCurrencyCell = (row) => accounting.formatMoney(row.itemPricingLabour);

  const renderEditableCell = ({ getValue, row, column, table }) => (
    <EditableCell
      data={{ getValue, row, column, table }}
      beforeSaveCell={beforeSaveCell}
      formatter={currencyFormatter}
      style={{ width: '65px' }}
    />
  );

  const parentColumns = [
    {
      header: 'Id',
      accessorKey: 'id',
    },
    {
      header: 'ItemPricingId',
      accessorKey: 'itemPricingId',
      enableColumnFilter: false,
    },
    {
      header: 'Date From',
      accessorKey: 'itemPricingDateFrom',
      enableColumnFilter: false,
    },
    {
      header: 'Date To',
      accessorKey: 'itemPricingDateTo',
      enableColumnFilter: false,
    },
    {
      header: 'Group',
      accessorKey: 'itemPricingGroup',
      enableColumnFilter: false,
    },
    {
      header: 'Fixed?',
      accessorKey: 'itemPricingFixed',
      enableColumnFilter: false,
      accessorFn: renderBooleanCell,
    },
    {
      header: 'OAC Labour',
      accessorKey: 'itemPricingLabour',
      enableColumnFilter: false,
      accessorFn: renderCurrencyCell,
    },
    {
      header: 'OAC Material',
      accessorKey: 'itemPricingMaterials',
      enableColumnFilter: false,
      accessorFn: renderCurrencyCell,
    },
    {
      header: 'OAC Total',
      accessorKey: 'itemPricingUnitPrice',
      enableColumnFilter: false,
      accessorFn: renderCurrencyCell,
    },
    {
      header: 'Int Labour',
      accessorKey: 'internalItemPricingLabour',
      enableColumnFilter: false,
      cell: renderEditableCell,
    },
    {
      header: 'Int Material',
      accessorKey: 'internalItemPricingMaterials',
      enableColumnFilter: false,
      cell: renderEditableCell,
    },
    {
      header: 'Int Total',
      accessorKey: 'internalItemPricingUnitPrice',
      enableColumnFilter: false,
      accessorFn: renderCurrencyCell,
    },
  ];

  return (
    <Card>
      <Card.Header
        style={{
          backgroundColor: '#3086ff',
          color: 'white',
          borderTopLeftRadius: '0.2rem',
          borderTopRightRadius: '0.2rem',
        }}
      >
        <strong>Details</strong>
      </Card.Header>
      <Card.Body>
        <Table size="sm" className="my-2">
          <thead>
            <tr>
              <td width="20%">
                <strong>Id:</strong>
              </td>
              <td>{id}</td>
            </tr>
            <tr>
              <td width="20%">
                <strong>Description:</strong>
              </td>
              <td>{itemDescription}</td>
            </tr>
            <tr>
              <td width="20%">
                <strong>Notes:</strong>
              </td>
              <td>{itemNotes || '-'}</td>
            </tr>
            <tr>
              <td width="20%">
                <strong>Unit of Measure:</strong>
              </td>
              <td>{itemUnitOfMeasure}</td>
            </tr>
            <tr>
              <td width="20%">
                <strong>Minimum Charge Quantity:</strong>
              </td>
              <td>{minimumChargeQuantity}</td>
            </tr>
            <tr>
              <td width="20%">
                <strong>Minimum Charge Unit of Measure:</strong>
              </td>
              <td>{minimumChargeUnitOfMeasure}</td>
            </tr>
            <tr>
              <td width="20%">
                <strong>Category:</strong>
              </td>
              <td>{itemCategory}</td>
            </tr>
            <tr>
              <td width="20%">
                <strong>Validation Notes:</strong>
              </td>
              <td>{itemValidationNotes || '-'}</td>
            </tr>
            <tr>
              <td width="20%">
                <strong>Minimum Charge Notes:</strong>
              </td>
              <td>{itemMinimumChargeNotes || '-'}</td>
            </tr>
            <tr>
              <td width="20%">
                <strong>Custom Pricing?:</strong>
              </td>
              <td>{itemCustom ? 'Yes' : 'No'}</td>
            </tr>
          </thead>
        </Table>
        <Card.Subtitle className="mt-4 mb-2">Item Pricings</Card.Subtitle>
        <ReactTable
          rootName={tableStateKey}
          parentColumns={parentColumns}
          data={orderBy(
            oacItemPricings,
            ['itemPricingGroup', 'itemPricingDateFrom'],
            ['asc', 'asc']
          )}
          doShow={false}
          doEdit={false}
          hideActions
          hiddenColumns={['id', 'itemPricingId']}
          setTableClasses={() => 'table-layout-fixed'}
          hideResetTable
        />
      </Card.Body>
    </Card>
  );
};

export default OacItemListOacItemShow;
