import { createSlice } from '@reduxjs/toolkit';
import get from 'lodash.get';
import set from 'lodash.set';
import cloneDeep from 'lodash.clonedeep';

const unhydratedInitialState = {
  trackedMutationCount: 0,
  tenant: '',
  tenantLongName: '',
  tenantShortName: '',
  tenantHasReinspections: false,
  tenantHasQccInspections: false,
  versionCurrent: true,
  mutating: false,
  online: false,
  deviceOnline: false,
  serverOnline: false,
};
const initialState = {
  ...unhydratedInitialState,
  bootswatchStyle: 'sandstone',
  isOpenSidebar: true,
  tableState: {
    user: {
      pagination: { pageIndex: 0, pageSize: 100 },
      sorting: [],
      columnFilters: [],
    },
    claim: {
      pagination: { pageIndex: 0, pageSize: 100 },
      sorting: [],
      columnFilters: [],
    },
    claimItem: {
      pagination: { pageIndex: 0, pageSize: 100 },
      sorting: [],
      columnFilters: [],
    },
    claimClaimImage: {
      pagination: { pageIndex: 0, pageSize: 100 },
      sorting: [],
      columnFilters: [],
    },
    claimInspected: {
      pagination: { pageIndex: 0, pageSize: 100 },
      sorting: [],
      columnFilters: [],
    },
    claimReinspection: {
      pagination: { pageIndex: 0, pageSize: 100 },
      sorting: [],
      columnFilters: [],
    },
    claimQccInspection: {
      pagination: { pageIndex: 0, pageSize: 100 },
      sorting: [],
      columnFilters: [],
    },
    claimInternalNote: {
      pagination: { pageIndex: 0, pageSize: 100 },
      sorting: [],
      columnFilters: [],
    },
    oacItemPricing: {
      pagination: { pageIndex: 0, pageSize: 100 },
      sorting: [],
      columnFilters: [],
    },
    oacReferral: {
      pagination: { pageIndex: 0, pageSize: 100 },
      sorting: [],
      columnFilters: [],
    },
    workCenterItemPricing: {
      pagination: { pageIndex: 0, pageSize: 100 },
      sorting: [],
      columnFilters: [],
    },
  },
};

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    settingsSet(state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
    settingsReset(state, action) {
      const initialStateKeys = action.payload;
      const initialStateData = get(initialState, initialStateKeys);
      if (initialStateData) {
        const newState = cloneDeep(state);
        set(newState, initialStateKeys, initialStateData);
        return newState;
      }
      return {
        ...state,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase('persist/REHYDRATE', (_, action) => {
        const persistedState = get(action, ['payload', 'settings'], {});
        const persistedTableState = get(persistedState, 'tableState', {});
        return {
          ...initialState,
          ...persistedState,
          // stop rehydrate overwriting new table states
          tableState: {
            ...initialState.tableState,
            ...persistedTableState,
          },
          ...unhydratedInitialState,
        };
      })
      .addDefaultCase((state) => state);
  },
});

export const { settingsSet, settingsReset } = settingsSlice.actions;
export default settingsSlice.reducer;
