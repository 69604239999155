export const userIntegers = [];
export const userFloats = [];
export const userIsoStrings = [];
export const userWhiteList = [
  'id',
  'fullName',
  'mobilePhone',
  'email',
  'admin',
  'inspector',
];
// export const userOptimisticList = [...userWhiteList];
export const userOptimisticList = ['updatedAt', ...userWhiteList];
