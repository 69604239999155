import { useMemo, useCallback } from 'react';
import {
  Col,
  Row,
  Card,
  Button,
  ButtonToolbar,
  ButtonGroup,
  Form,
} from 'react-bootstrap';
import { useQuery, useMutation, NetworkStatus } from '@apollo/client';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { Form as FinalForm } from 'react-final-form';
import { v4 as uuidv4, validate as uuidValidate } from 'uuid';
import Compressor from 'compressorjs';
import heic2any from 'heic2any';

import get from 'lodash.get';
import forEach from 'lodash.foreach';
import last from 'lodash.last';
import cloneDeep from 'lodash.clonedeep';

import LinkContainer from '../components/link_container';
import ReactTable from '../components/react_table/react_table';
import Field from '../components/form/rff_field';
import DropZoneField from '../components/form/drop_zone_field';
import InputField from '../components/form/input_field';
import ConditionalField from '../components/form/rff_conditional_field';
import { renderOverlay, renderOffline, renderError } from '../components/render_helpers';
import Confirm from '../components/confirm';
import SubmitButtonSpinner from '../components/submit_button_spinner';

import {
  claimClaimImageListPageQuery,
  claimClaimImageCreate as claimClaimImageCreateMutation,
  claimClaimImageDelete as claimClaimImageDeleteMutation,
} from '../graphql/claim_claim_image_queries';

import { settingsSet, settingsReset } from '../store/settings_slice';
import { toastSuccess, toastWarning, toastError } from '../lib/toast_helpers';
import { coerceInput } from '../lib/utils';
import { claimImageFormValidator } from '../validators';
import * as updateFunctions from '../update_functions';

const ClaimClaimImageList = () => {
  const tableStateKey = 'claimClaimImage';

  const params = useParams();
  const dispatch = useDispatch();

  const currentUser = useSelector((state) => state.auth.user);
  const settingsMutating = useSelector((state) => state.settings.mutating);
  const settingsOnline = useSelector((state) => state.settings.online);
  const settingsTenant = useSelector((state) => state.settings.tenant);

  const [claimClaimImageCreate] = useMutation(claimClaimImageCreateMutation);
  const [claimClaimImageDelete] = useMutation(claimClaimImageDeleteMutation);

  const {
    data: pageData,
    loading: pageLoading,
    error: pageError,
    refetch: pageRefetch,
    networkStatus: pageNetworkStatus,
  } = useQuery(claimClaimImageListPageQuery, {
    variables: { claimId: params.id },
    notifyOnNetworkStatusChange: true,
  });

  const pageLoadedOrRefetching = useMemo(
    () => !pageLoading || (pageLoading && pageNetworkStatus === NetworkStatus.refetch),
    [pageLoading, pageNetworkStatus]
  );

  const tableResetClicked = useCallback(() => {
    dispatch(settingsReset(['tableState', tableStateKey]));
  }, [tableStateKey, dispatch]);

  const claim = useMemo(() => get(pageData, ['claim']), [pageData]);

  const claimImages = useMemo(() => {
    if (claim) {
      return get(claim, ['claimImages'], []);
    }
    return [];
  }, [claim]);

  const isFirstReinspection = useMemo(() => {
    if (claim && claim.firstReinspection && !claim.secondReinspection) {
      return true;
    }
    return false;
  }, [claim]);

  const isSecondReinspection = useMemo(() => {
    if (claim && claim.secondReinspection) {
      return true;
    }
    return false;
  }, [claim]);

  const isReinspection = useMemo(() => {
    if (isFirstReinspection || isSecondReinspection) {
      return true;
    }
    return false;
  }, [isFirstReinspection, isSecondReinspection]);

  const isQccInspection = useMemo(() => {
    if (claim && claim.qccInspections && claim.qccInspections.length > 0) {
      return true;
    }
    return false;
  }, [claim]);

  const currentQccInspection = useMemo(() => {
    if (isQccInspection) {
      const sortedQccInspections = cloneDeep(get(claim, 'qccInspections', [])).sort(
        (a, b) => b.inspectionDate.localeCompare(a.inspectionDate)
      );
      return get(sortedQccInspections, [0]);
    }
    return undefined;
  }, [claim, isQccInspection]);

  const isInitialInspection = useMemo(() => {
    if (claim && !isReinspection && !isQccInspection) {
      return true;
    }
    return false;
  }, [claim, isReinspection, isQccInspection]);

  const claimImageDeleteClicked = async (e) => {
    const dataId = e.currentTarget.getAttribute('data-id');
    const claimImageId = uuidValidate(dataId)
      ? dataId
      : parseInt(e.currentTarget.getAttribute('data-id'), 10);
    const mutationData = {
      variables: { claimId: params.id, id: claimImageId },
      context: {
        serializationKey: `${settingsTenant}-images`,
        tracked: true,
        recordType: 'ClaimImageType',
        recordId: claimImageId,
        mutationType: 'DELETE',
      },
      update: updateFunctions.claimClaimImageDelete,
    };
    mutationData.optimisticResponse = updateFunctions.optimistic(
      'claimClaimImageDelete',
      mutationData,
      { claimId: params.id }
    );
    dispatch(
      settingsSet({
        mutating: true,
      })
    );
    if (settingsOnline) {
      try {
        claimClaimImageDelete(mutationData);
        toastSuccess('Claim Image delete ok');
      } catch (err) {
        console.log(err.toString());
        toastError('Claim Image delete failed');
      } finally {
        dispatch(
          settingsSet({
            mutating: false,
          })
        );
      }
    } else {
      claimClaimImageDelete(mutationData);
      toastWarning(`Claim Image delete ok locally. Go online to make permanent`);
      dispatch(
        settingsSet({
          mutating: false,
        })
      );
    }
  };

  const claimImageDeleteAllClicked = () => {
    forEach(get(pageData, 'claim.claimImages', []), (claimImage) => {
      claimImageDeleteClicked({ currentTarget: { getAttribute: () => claimImage.id } });
    });
  };

  const convertHEICFileToJpeg = async (HEICFile) =>
    heic2any({
      blob: HEICFile,
      toType: 'image/jpeg',
    });

  const getCompressedFiles = async (files) => {
    const compressedFilesPromises = files.map(async (file) => {
      let fileToBeCompressed = file;
      if (fileToBeCompressed.name.includes('.heic')) {
        fileToBeCompressed = await convertHEICFileToJpeg(file);
        fileToBeCompressed.name = `${file.name.substring(
          0,
          file.name.lastIndexOf('.')
        )}.jpg`;
      }
      return new Promise((resolve) => {
        // eslint-disable-next-line no-unused-vars
        const imagesCompressor = new Compressor(fileToBeCompressed, {
          quality: 0.6,
          checkOrientation: false,
          success: (compressedFile) => resolve(compressedFile),
          error: (err) => {
            if (window.$NODE_ENV !== 'development') {
              window.Rollbar.info('Error in claimsImage compression', {
                err: err.message,
              });
            }
            resolve(file);
          },
        });
      });
    });
    try {
      return Promise.all(compressedFilesPromises);
    } catch (error) {
      return error;
    }
  };

  const onFormSubmit = async (data, form) => {
    const { firstReinspection, secondReinspection } = get(pageData, 'claim', {});
    const submitData = cloneDeep(data);
    const files = cloneDeep(submitData.files);
    delete submitData.files;

    const compressedFiles = await getCompressedFiles(files);
    if (submitData.roomOther && submitData.room !== 'Other') {
      submitData.roomOther = null;
    }

    if (isInitialInspection) {
      submitData.imageableId = params.id;
      submitData.imageableType = 'Claim';
    } else if (isFirstReinspection) {
      submitData.firstReinspectionId = firstReinspection.id;
      submitData.imageableId = firstReinspection.id;
      submitData.imageableType = 'FirstReinspection';
    } else if (isSecondReinspection) {
      submitData.secondReinspectionId = secondReinspection.id;
      submitData.imageableId = secondReinspection.id;
      submitData.imageableType = 'SecondReinspection';
    } else if (isQccInspection) {
      submitData.imageableId = currentQccInspection.id;
      submitData.imageableType = 'QccInspection';
    }

    submitData.claimId = params.id;

    const mutationData = {
      variables: { claimId: params.id, input: coerceInput(submitData) },
      context: {
        hasUpload: true,
        serializationKey: `${settingsTenant}-images`,
        tracked: true,
        recordType: 'ClaimImageType',
        mutationType: 'CREATE',
      },
      update: updateFunctions.claimClaimImageCreate,
    };
    // No checking of image uploads if offline
    if (settingsOnline) {
      toastSuccess(`Claim Image create succeeded`);
    } else {
      toastWarning(`Claim Image create succeeded`);
    }
    form.restart();
    // Just upload files in background...don't block no error handling here
    compressedFiles.map((file) => {
      const submitMutationData = cloneDeep(mutationData);
      const uuid = uuidv4();
      submitMutationData.context.recordId = uuid;
      submitMutationData.variables.input.file = file;
      submitMutationData.optimisticResponse = updateFunctions.optimistic(
        'claimClaimImageCreate',
        submitMutationData,
        {
          claimId: params.id,
          fileFilename: file.name,
          fileMimetype: file.type,
          filePathname: null,
          signedUrl: null,
        },
        ['file']
      );
      return claimClaimImageCreate(submitMutationData);
    });
  };

  const handleOnDrop = (form, files) => {
    form.change('files', files);
  };

  const claimImageEditEnabled = (claimImage) => {
    const { imageableType, firstReinspectionId, secondReinspectionId } = claimImage;
    if (
      !(isReinspection || isQccInspection) ||
      (isFirstReinspection && firstReinspectionId) ||
      (isSecondReinspection && secondReinspectionId) ||
      (isQccInspection && imageableType === 'QccInspection')
    ) {
      return true;
    }
    return false;
  };

  const renderFileNames = (files) => {
    if (files) {
      return files.map((f) => f.name);
    }
    return undefined;
  };

  const renderLocationCell = (row) => {
    if (row.roomOther) {
      return `${row.room} - ${row.roomOther}`;
    }
    return row.room;
  };

  const renderImageableTypeCell = (row) => {
    if (row.imageableType) {
      return row.imageableType;
    }
    if (row.firstReinspectionId) {
      return 'First Reinspection';
    }
    if (row.secondReinspectionId) {
      return 'Second Reinspection';
    }
    return 'Initial Inspection';
  };

  const renderActionsCell = ({ row: { original: row } }) =>
    claimImageEditEnabled(row) && (
      <Confirm
        dataId={row.id}
        onConfirm={claimImageDeleteClicked}
        title="Delete Image"
        body="This will DELETE the image."
      >
        <Button variant="outline-danger" size="small" className="w-100 mb-2">
          delete
        </Button>
      </Confirm>
    );

  const parentColumns = [
    {
      header: 'Id',
      accessorKey: 'id',
    },
    {
      header: 'Location',
      accessorKey: 'room',
      enableColumnFilter: false,
      accessorFn: renderLocationCell,
    },
    {
      header: 'File Name',
      accessorKey: 'fileFilename',
      enableColumnFilter: false,
    },
    {
      header: 'Type',
      accessorKey: 'fileMimetype',
      enableColumnFilter: false,
    },
    {
      header: 'Inspection Type',
      accessorKey: 'imageableType',
      enableColumnFilter: false,
      accessorFn: renderImageableTypeCell,
    },
  ];

  const renderContent = () => (
    <>
      <Row className="mt-4 mb-3">
        <Col>
          <h1 className="h3 mb-3">Claim Images</h1>
        </Col>
        <Col sm="auto">
          <ButtonToolbar>
            <ButtonGroup className="me-2">
              {(window.$NODE_ENV === 'development' || currentUser.limited) && (
                <Button
                  variant="primary"
                  onClick={claimImageDeleteAllClicked}
                  disabled={!settingsOnline}
                >
                  Delete all Images
                </Button>
              )}
              <LinkContainer to={`/claims/${params.id}`}>
                <Button variant="primary">Job Sheet</Button>
              </LinkContainer>
            </ButtonGroup>
            <ButtonGroup>
              <Button variant="primary" onClick={tableResetClicked}>
                Reset Table
              </Button>
              <Button
                variant="primary"
                onClick={() => pageRefetch()}
                disabled={!settingsOnline}
              >
                Refresh Data
              </Button>
            </ButtonGroup>
          </ButtonToolbar>
        </Col>
      </Row>
      {get(pageData, 'claim.claimImages') && (
        <Row>
          <Col>
            <Card>
              <Card.Body>
                <ReactTable
                  rootName={tableStateKey}
                  parentColumns={parentColumns}
                  data={claimImages}
                  doShow={false}
                  doEdit={false}
                  actionCell={renderActionsCell}
                  hiddenColumns={['id']}
                  hideResetTable
                />
              </Card.Body>
            </Card>
            <Card>
              <Card.Body>
                <FinalForm
                  initialValues={{
                    room: get(last(pageData.claim.claimImages), 'room', ''),
                    roomOther: get(last(pageData.claim.claimImages), 'roomOther', ''),
                  }}
                  onSubmit={(data, form) => onFormSubmit(data, form)}
                  validate={claimImageFormValidator}
                >
                  {({ handleSubmit, pristine, form, submitting, values }) => (
                    <form noValidate>
                      <Field
                        type="text"
                        name="files"
                        component={DropZoneField}
                        filenames={renderFileNames(values.files)}
                        handleOnDrop={(files) => handleOnDrop(form, files)}
                        allowMultiple
                        groupClassName="mb-3"
                      />
                      <Field
                        name="room"
                        component={InputField}
                        asElement="select"
                        selectOptions={[
                          ...pageData.enums.enums.ImageRooms,
                          ...pageData.enums.enums.ImageAdhoc,
                        ].map((room) => ({
                          id: room,
                          name: room,
                        }))}
                        defaultSelectOptionName="select image location..."
                        labelWidth={4}
                        inputWidth={8}
                        size="lg"
                      >
                        Image Location
                      </Field>
                      <ConditionalField when="room" is="Other">
                        <Field
                          name="roomOther"
                          component={InputField}
                          labelWidth={4}
                          inputWidth={8}
                          size="lg"
                        >
                          Image Location Other
                        </Field>
                      </ConditionalField>
                      <Form.Group as={Row}>
                        <Col sm={12}>
                          <ButtonToolbar style={{ justifyContent: 'flex-end' }}>
                            <ButtonGroup className="me-2">
                              <Button
                                variant="danger"
                                onClick={() => form.reset()}
                                disabled={pristine || submitting}
                              >
                                Cancel
                              </Button>
                              <Button
                                type="button"
                                variant="primary"
                                disabled={pristine || submitting}
                                onClick={handleSubmit}
                              >
                                {submitting && <SubmitButtonSpinner />}
                                Create
                              </Button>
                            </ButtonGroup>
                          </ButtonToolbar>
                        </Col>
                      </Form.Group>
                      <hr />
                    </form>
                  )}
                </FinalForm>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}
    </>
  );

  return (
    <div>
      {renderOverlay(pageLoading, settingsMutating, settingsOnline)}
      {renderOffline(settingsOnline)}
      {renderError(pageError)}
      {!pageError && pageLoadedOrRefetching && renderContent()}
    </div>
  );
};

export default ClaimClaimImageList;
