import { useMemo, useCallback } from 'react';
import { Col, Row, Card, Button, ButtonToolbar, ButtonGroup } from 'react-bootstrap';
import { useQuery, useMutation, NetworkStatus } from '@apollo/client';
import { useSelector, useDispatch } from 'react-redux';

import moment from 'moment';

import get from 'lodash.get';

import LinkContainer from '../components/link_container';
import { renderOverlay, renderOffline, renderError } from '../components/render_helpers';
import ReactTable from '../components/react_table/react_table';

import {
  claimListPageQuery,
  claimInitialPoll as claimInitialPollMutation,
} from '../graphql/claim_queries';

import { toastSuccess, toastError } from '../lib/toast_helpers';
import { handleSubmitError } from '../lib/utils';
import * as updateFunctions from '../update_functions';
import { settingsReset } from '../store/settings_slice';

const ClaimList = () => {
  const tableStateKey = 'claim';
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.auth.user);
  const settingsMutating = useSelector((state) => state.settings.mutating);
  const settingsOnline = useSelector((state) => state.settings.online);

  const [claimInitialPoll] = useMutation(claimInitialPollMutation);

  const {
    data: pageData,
    loading: pageLoading,
    error: pageError,
    refetch: pageRefetch,
    networkStatus: pageNetworkStatus,
    client: pageClient,
  } = useQuery(claimListPageQuery, {
    notifyOnNetworkStatusChange: true,
    variables: { listType: currentUser.admin ? 'adminList' : 'inspectorList' },
    onCompleted: ({ claimList }) => updateFunctions.claimList(pageClient, claimList),
  });

  const pageLoadedOrRefetching = useMemo(
    () => !pageLoading || (pageLoading && pageNetworkStatus === NetworkStatus.refetch),
    [pageLoading, pageNetworkStatus]
  );

  const tableResetClicked = useCallback(() => {
    dispatch(settingsReset(['tableState', tableStateKey]));
  }, [tableStateKey, dispatch]);

  const claimList = useMemo(() => get(pageData, ['claimList'], []), [pageData]);

  const claimInitialPollClicked = () =>
    // eslint-disable-next-line implicit-arrow-linebreak
    claimInitialPoll()
      .then(() => {
        toastSuccess(`Initial Inspection Poll requested`);
      })
      .catch((err) => {
        const { errorMessage } = handleSubmitError(err);
        toastError(errorMessage);
      });

  const renderDateCell = ({ riScheduledInspectionDate }) =>
    moment(riScheduledInspectionDate).format('ll');

  const renderAddressCell = (cellValue) =>
    cellValue.split(',').map((c) => (
      <span key={c}>
        {c}
        <br />
      </span>
    ));

  const renderActionsCell = ({ row: { original: row } }) => (
    <>
      <LinkContainer to={`/claims/${row.id}`}>
        <Button variant="outline-primary" size="small" className="w-100 mb-2">
          Job Sheet
        </Button>
      </LinkContainer>
      <br />
    </>
  );

  const parentColumns = [
    {
      header: 'Id',
      accessorKey: 'id',
    },
    {
      header: 'Job Number',
      accessorKey: 'riJobNumber',
      filterFn: 'includesString',
    },
    {
      header: 'Claim Number',
      accessorKey: 'claimNo',
      filterFn: 'includesString',
    },
    {
      header: 'Inspector',
      accessorKey: 'riInspector',
      filterType: 'dropdown',
    },
    {
      header: 'Client Name',
      accessorKey: 'riClientName',
      filterFn: 'includesString',
    },
    {
      header: 'Address',
      accessorKey: 'situationOfRisk',
      filterFn: 'includesString',
      cell: ({ cell }) => renderAddressCell(cell.getValue()),
    },
    {
      header: 'Scheduled At',
      accessorKey: 'riScheduledInspectionDate',
      enableColumnFilter: false,
      accessorFn: renderDateCell,
    },
    {
      header: 'Job Status',
      accessorKey: 'riStatus',
      filterType: 'dropdown',
    },
    {
      header: 'Submitted At',
      accessorKey: 'submittedAt',
    },
  ];

  const renderContent = () => (
    <>
      {/* <Modal show={!!modalItemId} onHide={hideErrorItemModal} centered>
        <Modal.Header>
          <Modal.Title>Confirm Schedule of Work Electrical</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Confirm the schedule of works includes electrical disconnection and reconnection tasks
            if required.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button type="button" variant="danger" onClick={continueErrorItemModal}>
            Confirm Electrial
          </Button>
          <LinkContainer to={`/claims/${modalItemId}/edit`}>
            <Button type="button" variant="primary">
              Open Schedule of Work
            </Button>
          </LinkContainer>
          <Button type="button" variant="primary" onClick={hideErrorItemModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal> */}

      <Row className="mt-4 mb-3">
        <Col>
          <h1 className="h3 mb-3">Claims</h1>
        </Col>
        <Col sm="auto">
          <ButtonToolbar>
            <ButtonGroup className="me-2">
              {currentUser.admin && (
                <Button
                  variant="primary"
                  onClick={claimInitialPollClicked}
                  disabled={!settingsOnline}
                >
                  Poll Initial
                </Button>
              )}
            </ButtonGroup>
            <ButtonGroup>
              <Button variant="primary" onClick={tableResetClicked}>
                Reset Table
              </Button>
              <Button
                variant="primary"
                onClick={() => pageRefetch()}
                disabled={!settingsOnline}
              >
                Refresh Data
              </Button>
            </ButtonGroup>
          </ButtonToolbar>
        </Col>
      </Row>

      <Row>
        <Col>
          <Card>
            <Card.Body>
              <ReactTable
                rootName={tableStateKey}
                parentColumns={parentColumns}
                data={claimList}
                doShow={false}
                doEdit={false}
                actionCell={renderActionsCell}
                hiddenColumns={['id', 'submittedAt']}
                setTableClasses={() => 'table-layout-fixed'}
                hideResetTable
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );

  return (
    <div>
      {renderOverlay(pageLoading, settingsMutating, settingsOnline)}
      {renderOffline(settingsOnline)}
      {renderError(pageError)}
      {(currentUser.admin || currentUser.inspector) &&
        !pageError &&
        pageLoadedOrRefetching &&
        renderContent()}
    </div>
  );
};

export default ClaimList;
