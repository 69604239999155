import { Card, Table, Button } from 'react-bootstrap';

import accounting from 'accounting';
import moment from 'moment';
import { Trash as TrashIcon, Edit as EditIcon } from 'react-feather';

import clone from 'lodash.clone';
import sortBy from 'lodash.sortby';
import reverse from 'lodash.reverse';

import ReactTable from '../react_table/react_table';

import Confirm from '../confirm';

const WorkCenterCategoryListWorkCenterItemShow = ({
  workCenterItem,
  workCenterItemEditClicked,
  workCenterItemDeleteClicked,
  tableStateKey,
}) => {
  const {
    id,
    position,
    itemDescription,
    itemCostType,
    itemUnitOfMeasure,
    itemMinimumChargeNotes,
    itemCustom,
    minimumChargeUnitOfMeasure,
    minimumChargeQuantity,
    workCenterItemPricings,
  } = workCenterItem;

  const sortedWorkCenterItemPricings = () =>
    reverse(sortBy(clone(workCenterItemPricings), ['createdAt']));

  const setRowStyles = (row) => (row.index === 0 ? { backgroundColor: '#ffe6e6' } : {});

  const renderBooleanCell = (row) => (row.itemPricingFixed ? 'Yes' : 'No');
  const renderCurrencyCell = (row) => accounting.formatMoney(row.itemPricingUnitPrice);
  const renderDateCell = (row) => moment(row.createdAt).format('MMM D, YYYY h:mm:ss A');

  const parentColumns = [
    {
      header: 'Id',
      accessorKey: 'id',
    },
    {
      header: 'Created At',
      accessorKey: 'createdAt',
      enableColumnFilter: false,
      accessorFn: renderDateCell,
    },
    {
      header: 'Fixed Price',
      accessorKey: 'itemPricingFixed',
      enableColumnFilter: false,
      accessorFn: renderBooleanCell,
    },
    {
      header: 'Price',
      accessorKey: 'itemPricingUnitPrice',
      enableColumnFilter: false,
      accessorFn: renderCurrencyCell,
    },
  ];

  return (
    <Card>
      <Card.Header
        style={{
          backgroundColor: '#3086ff',
          color: 'white',
          borderTopLeftRadius: '0.2rem',
          borderTopRightRadius: '0.2rem',
        }}
      >
        <strong>Details</strong>
        <Button onClick={workCenterItemEditClicked} data-id={id} size="sm">
          <EditIcon size="16" />
        </Button>
        <Confirm
          dataId={workCenterItem.id}
          onConfirm={workCenterItemDeleteClicked}
          title="Delete Work Center Item"
          body="Are you sure you want to delete this work center item?"
        >
          <Button variant="danger" size="sm">
            <TrashIcon size="16" />
          </Button>
        </Confirm>
      </Card.Header>
      <Card.Body>
        <Table size="sm" className="my-2">
          <thead>
            <tr>
              <td width="20%">
                <strong>Id:</strong>
              </td>
              <td>{id}</td>
            </tr>
            <tr>
              <td width="20%">
                <strong>Position:</strong>
              </td>
              <td>{position}</td>
            </tr>
            <tr>
              <td width="20%">
                <strong>Description:</strong>
              </td>
              <td>{itemDescription}</td>
            </tr>
            <tr>
              <td width="20%">
                <strong>Cost Type:</strong>
              </td>
              <td>{itemCostType}</td>
            </tr>
            <tr>
              <td width="20%">
                <strong>Unit of Measure:</strong>
              </td>
              <td>{itemUnitOfMeasure}</td>
            </tr>
            <tr>
              <td width="20%">
                <strong>Minimum Charge Quantity:</strong>
              </td>
              <td>{minimumChargeQuantity}</td>
            </tr>
            <tr>
              <td width="20%">
                <strong>Minimum Charge Unit of Measure:</strong>
              </td>
              <td>{minimumChargeUnitOfMeasure}</td>
            </tr>
            <tr>
              <td width="20%">
                <strong>Minimum Charge Notes:</strong>
              </td>
              <td>{itemMinimumChargeNotes || '-'}</td>
            </tr>
            <tr>
              <td width="20%">
                <strong>Custom Pricing?:</strong>
              </td>
              <td>{itemCustom ? 'Yes' : 'No'}</td>
            </tr>
          </thead>
        </Table>
        <Card.Subtitle className="mt-4 mb-2">Item Pricings</Card.Subtitle>
        <ReactTable
          rootName={tableStateKey}
          parentColumns={parentColumns}
          data={sortedWorkCenterItemPricings()}
          doShow={false}
          doEdit={false}
          hideActions
          hiddenColumns={['id']}
          setRowStyles={setRowStyles}
          hideResetTable
        />
      </Card.Body>
    </Card>
  );
};

export default WorkCenterCategoryListWorkCenterItemShow;
